import helpers from '@/assets/js/shared/misc/helpers';
import NBars from 'nbars/index.mjs';
const spaceRegEx = new RegExp(/' '|\+/, 'g');
const dashRegEx = new RegExp('-', 'g');
// process.env.website will work since it's part of the build config (nuxt.config.js "env" property)
const website = process.env.website;
let pageCms = {};
try {
  pageCms = require(`@/assets/cms/pages/results.json`);
} catch (err) {
  // Do nothing:
}
// TODO: Remove functions once unmask cms is organized like checkpeople
function getSeparatorData() {
  // For checkpeople get data from personCard object
  if (website === 'checkpeople' || website === 'information') {
    // Get symbols from the person card
    return {
      address: pageCms.person_card.separator_address,
      general: pageCms.person_card.separator_symbol,
      masking: pageCms.person_card.masking_symbol
    };
  }

  // All others use unnested data
  const {
    card_separator_address: cardSeparatorAddress,
    card_separator_symbol: cardSeparatorSymbol,
    card_masking_symbol: cardMaskingSymbol
  } = pageCms;

  return {
    address: cardSeparatorAddress,
    general: cardSeparatorSymbol,
    masking: cardMaskingSymbol
  };
}
function getQnaData() {
  // For checkpeople get data from qna object
  if (website === 'checkpeople' || website === 'information') {
    return {
      qna_title: pageCms.qna.title,
      qna_subtext: pageCms.qna.subtext
    };
  }
  // All others use unnested data
  const { qna_title: qnaTitle, qna_subtext: qnaSubtext } = pageCms;
  return {
    qna_title: qnaTitle,
    qna_subtext: qnaSubtext
  };
}
function getSocialData() {
  // For checkpeople get data from social media object
  if (website === 'checkpeople') {
    return {
      social_media_title: pageCms.social_media.title,
      social_media_cta_title: pageCms.social_media.cta_title
    };
  }
  // All others use unnested data
  const {
    social_media_title: socialMediaTitle,
    social_media_cta_title: socialMediaCtaTitle
  } = pageCms;

  return {
    social_media_title: socialMediaTitle,
    social_media_cta_title: socialMediaCtaTitle
  };
}

const { landing_url: landingUrl } = pageCms;
// TODO: Retrieve from CMS without methods once unmask cms is organized like checkpeople
const { qna_title: qnaTitle, qna_subtext: qnaSubtext } = getQnaData();
const {
  social_media_title: socialMediaTitle,
  social_media_cta_title: socialMediaCtaTitle
} = getSocialData();
const symbols = getSeparatorData();

const resultHelper = {
  ...helpers,
  /**
   * determineFlowType
   * @param {boolean} isState - bool representing if state params are present
   * @param {boolean} isCity - bool representing if city params are present
   * @returns {string}
   */
  determineFlowType: function(isState, isCity) {
    if (isState) {
      return 'state';
    }
    if (isCity) {
      return 'city';
    }

    return 'root';
  },
  /**
   * getCrimeData - iterates through data to find crime info, then runs it through formatter
   * @param {object} payload - the existing formatted payload
   * @returns {{criminalRecordsSexOffenderCount, crimes: ([]|*), limitedCrimes}}
   */
  getCrimeData: function(payload) {
    const filterCrime = crimeArray => {
      // handles filtering of crime data
      if (!Array.isArray(crimeArray) || crimeArray.length === 0) {
        return [];
      }

      return crimeArray.filter(item => item.has_crimes);
    };

    const crimes = filterCrime(payload.peopleResponse);
    const { limitedCrimes, criminalRecordsSexOffenderCount } = this.setCrimes(
      crimes,
      4,
      false
    );

    return {
      crimes,
      limitedCrimes,
      criminalRecordsSexOffenderCount
    };
  },
  getTitleFormatCityName: function(city) {
    if (typeof city !== 'string') {
      return '';
    }

    return city.includes('-') ? city.split('-').join(' ') : city;
  },
  /**
   * queryWithSearchSDK - a helper to query from the searchSDK without having to throw try/catch blocks all over
   * @param {object} queryParams - the data required for the passed search method
   * @param {function} searchMethod - a function pointer to a searchSDK method eg. LocalPeopleFull.byLocation
   * @param {any} defaultReturnValue - the value the method should return if the query fails
   * @returns {Promise<*|*|*[]>}
   */
  queryWithSearchSDK: async function(
    queryParams,
    searchMethod,
    defaultReturnValue
  ) {
    try {
      return await searchMethod(queryParams);
    } catch (e) {
      console.error(e);
      return defaultReturnValue ? defaultReturnValue : [];
    }
  },
  /**
   * getStateCount - counts the states that match the selected route
   * @param {array} stateList - a list of states from server
   * @param {string} stateAbbr - an all caps abbreviation of a state eg. OH
   * @returns {number|*} - the count of this state
   */
  getStateCount: function(stateList, stateAbbr) {
    if (!Array.isArray(stateList) || stateList.length === 0) {
      return 0;
    }
    return stateList.filter(item => item.state === stateAbbr)[0];
  },
  /**
   * setApiLimit - a function to limit the query results to a specific amount
   * @param {Object} currentPayload - an existing query object
   * @param {number} limit - a number that you want the limit to be eg. 250 most of the time
   * @param {number | null} page - the page the user is currently on in the search results
   * @param {string} type - tells method what logic to use
   * @returns {number|*|number} - returns the api limit that was calculated
   */
  setApiLimit: function(currentPayload, limit, page, type) {
    switch (type) {
      case 'results':
        return currentPayload.totalPeople >= limit
          ? currentPayload.itemsPerPage * page
          : limit;
      case 'peopleItems':
        return currentPayload.totalPeople >= limit
          ? limit
          : currentPayload.peopleResponse.length;
      default:
        return 0;
    }
  },
  /**
   * Return the total amount to display - total possible or length of array, whichever is smaller
   *
   * @param array
   * @param totalPossible
   * @returns {int}
   */
  calculateDisplayMax: function(array, totalPossible) {
    return array.length < totalPossible ? array.length : totalPossible;
  },
  /**
   * Encode TLO CID
   *
   * @param {string} cid
   * @returns {string}
   */
  encodeCID: function(cid) {
    // prevents the whole page from breaking when a person doesn't have a cid
    if (!cid) {
      return '';
    }

    let encodedString = '';
    let unicodeCharString;

    for (let i = 0; i < cid.length; i++) {
      unicodeCharString = cid.charCodeAt(i).toString(16);
      encodedString +=
        unicodeCharString.length < 2
          ? '0' + unicodeCharString
          : unicodeCharString;
    }

    return encodedString;
  },
  /**
   * Decode TLO CID
   *
   * @param {string} cid
   * @returns {string|boolean}
   */
  decodeCID: function(cid) {
    let decodedString = '';

    for (let i = 0; i < cid.length; i += 2) {
      const thisBase16Int = parseInt(cid.substr(i, 1), 16);
      const nextBase16Int = parseInt(cid.substr(i + 1, 1), 16);

      if (isNaN(thisBase16Int) || isNaN(nextBase16Int)) {
        return false;
      }

      decodedString += String.fromCharCode(
        (thisBase16Int << 4) | nextBase16Int
      );
    }

    return decodedString;
  },
  /**
   * Return the QNA tokens required for display
   *
   * @param {array} people
   * @param {string} firstName
   * @param {string} lastName
   * @returns {{emails: (string|null), firstName: string, lastName: string, aliases: (string|null), address: (string|null), phone: (string|null), fullName: string, alias: (string|null), oldestAge: *, age: *, email: (string|null), youngestAge: *}}
   */
  getQnaTokens: function(people, firstName, lastName, seoPhone) {
    const agesItems = [...people]
      .filter(person => person.age)
      .sort((a, b) => (a.age > b.age ? 1 : -1))
      .map(person => person.age);
    const dobItems = [...people]
      .filter(person => {
        const cutoffDate = new Date('1900-01-01');
        const date = new Date(person.dob);
        if (date > cutoffDate) return date;
      })
      .sort((a, b) => (a.dob > b.dob ? 1 : -1))
      .map(person => new Date(person.dob).getFullYear());

    const addressItems = [...people]
      .filter(
        person => Array.isArray(person.addresses) && person.addresses.length > 0
      )
      .map(person => [person.addresses[0]])[0];

    const phoneItems = [...people]
      .filter(person => (person.phones ? person.phones.length > 0 : null))
      .map(person => [person.phones[0]])[0];

    const emailItemsSingular = [...people]
      .filter(person => (person.emails ? person.emails.length === 1 : null))
      .map(person => {
        return person.emails;
      })[0];
    const emailItemsMultiple = [...people]
      .filter(person => (person.emails ? person.emails.length > 1 : null))
      .map(person => {
        return person.emails;
      })[0];
    const aliasItemsSingular = [...people]
      .filter(person => (person.aliases ? person.aliases.length === 1 : null))
      .map(person => {
        return person.aliases;
      })[0];
    const aliasItemsMultiple = [...people]
      .filter(person => (person.aliases ? person.aliases.length > 1 : null))
      .map(person => {
        return person.aliases;
      })[0];
    const relativesItemsMultiple = [...people]
      .filter(person => (person.relatives ? person.relatives.length > 1 : null))
      .map(person => {
        return person.relatives;
      })
      .filter(relative => relative.id)[0];
    const income = this.rand(45, 110);

    const tokens = {
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      year: dobItems[0],
      oldestDob: dobItems[0],
      youngestDob: dobItems[dobItems.length - 1],
      age: agesItems[0],
      youngestAge: agesItems[0],
      oldestAge: agesItems[agesItems.length - 1],
      address: this.formatAddressHistoryAsString(
        addressItems,
        1,
        false,
        1,
        0,
        true
      ),
      phone: this.formatPhoneNumbersAsString(
        phoneItems ? phoneItems : [],
        1,
        false,
        10,
        true
      ),
      seoPhone: resultHelper.formatPhoneNumber(seoPhone),
      email: this.formatEmailListAsString(emailItemsSingular, 1, true, false),
      alias: this.formatAliasList(aliasItemsSingular, 1, true),
      emails: this.formatEmailListAsString(emailItemsMultiple, 3, true, true),
      aliases: this.formatAliasList(aliasItemsMultiple, 3, true),
      relatives: this.formatRelativesList(relativesItemsMultiple, 3),
      relationships: this.rand(45, 60),
      incomeLow: income - 5,
      incomeHigh: income + 5
    };

    if (phoneItems && phoneItems.length > 0) {
      tokens['phoneRaw'] = resultHelper.dashedPhoneNumber(phoneItems[0].full);
    }

    // tokens
    return tokens;
  },

  /**
   * getRandomPhoneNumbers - selects random numbers from an array of people
   * @param {array} people - an array of people objects
   * @returns {string[]}
   */
  getRandomPhoneNumbers: function(people) {
    if (!Array.isArray(people)) {
      return [];
    }
    const randomPeople = this.getRandomItems(people, 6);
    const selectedPhoneNumbers = [];

    randomPeople.forEach(person => {
      if (Array.isArray(person.phones) && person.phones.length > 0) {
        selectedPhoneNumbers.push(person.phones[0]);
      }
    });

    const phoneString = this.formatPhoneNumbersAsString(
      selectedPhoneNumbers,
      6,
      false,
      10
    );
    return phoneString.split(', ');
  },
  /**
   * Return address as a title cased string with optional masking
   *
   * @param address
   * @param separator
   * @param shouldMaskStreet
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string}
   */
  formatAddress: function(
    address,
    separator,
    shouldMaskStreet,
    spaceBeforeSeparator = false
  ) {
    // Map over street address object to ensure title case
    let street =
      address.street_name || address.street_type
        ? [
            address.street_number,
            address.street_name,
            address.street_type,
            address.street_affix
          ]
            .map(function(item) {
              // changes street number to a string
              if (typeof item === 'number') {
                return item.toString();
              }
              return helpers.setTitleCase(item);
            })
            .filter(function(item) {
              return item !== undefined;
            })
        : [];
    // If we should mask the street name
    if (shouldMaskStreet && street !== []) {
      // Street name is the first and last item of street array with masking string in between
      street = `${street[0]} ${this.getMaskingString(5)} ${
        street[street.length - 1]
      }`;
      // Otherwise street name is recompiled with title case
    } else {
      street = street.join(' ');
    }

    // Return the newly formatted street name and remaining address data
    return `${street ? street + ', ' : ''}${this.formatAddressWithoutStreet(
      address,
      separator,
      spaceBeforeSeparator
    )}`;
  },
  /**
   * Convert address pieces from search params into a formatted string for display
   *
   * @param {string} streetRaw
   * @param {string} cityRaw
   * @param {string} stateAbbrRaw
   * @param {string} zipCode
   */
  formatFullAddressFromParams(streetRaw, cityRaw, stateAbbrRaw, zipCode) {
    const hasPOBox =
      streetRaw.toUpperCase().includes('PO ') ||
      streetRaw.toUpperCase().includes('P/O ');
    const street = hasPOBox
      ? streetRaw.replace(/Po|P\/O/, 'PO')
      : resultHelper.setTitleCase(streetRaw.replace(/-/g, ' '));
    const state = stateAbbrRaw.toUpperCase();
    const city = resultHelper.formatName(cityRaw.replace(/_/g, ' '));
    return `${street}, ${city}, ${state} ${zipCode}`;
  },
  /**
   * Convert an array of addresses into formatted strings for display
   *
   * @param {array} addressList
   * @param {number} displayCount
   * @param {boolean} shouldMaskStreet
   * @param {number} streetCount
   * @param {boolean} isRandom
   * @param {number} startAt
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string|null}
   */
  formatAddressHistoryAsString: function(
    addressList,
    displayCount,
    shouldMaskStreet,
    streetCount,
    startAt = 0,
    isRandom = false,
    spaceBeforeSeparator
  ) {
    // Early exit if no addresses
    if (!addressList) {
      return null;
    }

    // Get the amount to display on page
    const totalPossible = resultHelper.calculateDisplayMax(
      addressList,
      displayCount
    );

    let formattedAddresses = '';
    let items = addressList;
    if (isRandom) {
      items = this.getRandomItems(addressList, totalPossible);
    }

    // Go through each address we can display
    for (let i = startAt; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;
      // Format address with street name only if it is available
      if (i < streetCount) {
        formattedAddresses += this.formatAddress(
          items[i],
          this.getSeparatorSymbol(isLast, true),
          shouldMaskStreet,
          spaceBeforeSeparator
        );
        // Otherwise format address with only city, state, and zip
      } else {
        const separatorSymbol = this.getSeparatorSymbol(isLast, true);
        const shortenedAddress = this.formatAddressWithoutStreet(
          addressList[i],
          separatorSymbol,
          spaceBeforeSeparator
        );
        const shortAddressWithNoSeparator = shortenedAddress.replace(
          separatorSymbol,
          ''
        );
        if (!formattedAddresses.includes(shortAddressWithNoSeparator)) {
          formattedAddresses += shortenedAddress;
        }
      }
    }
    if (formattedAddresses.endsWith(this.getSeparatorSymbol(false, true))) {
      const addressesWithoutEndSeparator = formattedAddresses.substring(
        0,
        formattedAddresses.length - 2
      );
      formattedAddresses = addressesWithoutEndSeparator;
    }

    return formattedAddresses;
  },
  /**
   * Return formatted city, state, and zipcode
   *
   * @param address
   * @param separator
   * @param {boolean|null} spaceBeforeSeparator
   * @returns {string}
   */
  formatAddressWithoutStreet: function(
    address,
    separator,
    spaceBeforeSeparator = false
  ) {
    // Split the city name on any spacing
    let city =
      typeof address === 'object' && address.city
        ? address.city.split(' ')
        : null;

    if (city) {
      // Ensure every part of the city name is title case
      for (let i = 0; i < city.length; i++) {
        city[i] = helpers.setTitleCase(city[i]);
      }

      city = city.join(' ');
    }

    // Return the title cased city name, state, and zip code
    return `${city ? city + ',' : ''} ${address.state} ${address.zip}${
      spaceBeforeSeparator ? ' ' : ''
    }${separator}`;
  },
  /**
   * Return an array of aliases as a formatted string for display
   *
   * @param {array} aliasList
   * @param {number} displayCount
   * @param {boolean} isRandom
   * @returns {null|string}
   */
  formatAliasList: function(aliasList, displayCount, isRandom = false) {
    // Early exit if no aliasList
    if (!aliasList) {
      return null;
    }

    // Get total possible for display
    const totalPossible = this.calculateDisplayMax(aliasList, displayCount);

    let formattedAlias = '';
    let items = aliasList;
    if (isRandom) {
      items = this.getRandomItems(aliasList, totalPossible);
    }

    // Go through each alias and format for page
    for (let i = 0; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;

      formattedAlias += `${helpers.formatName(
        items[i]
      )}${this.getSeparatorSymbol(isLast)}`;
    }

    return formattedAlias === '' ? null : formattedAlias;
  },
  /**
   * Return an array of relatives as a formatted string for display
   * and linked profiles
   * @param relatives
   * @param displayCount
   * @returns {null|string}
   */
  formatRelativesList: function(relatives, displayCount) {
    // Early exit if no relatives
    if (!relatives) {
      return null;
    }

    // Get total possible for display
    const totalPossible = this.calculateDisplayMax(relatives, displayCount);

    let formatted = '';
    const items = this.getRandomItems(relatives, totalPossible);

    // Go through each relative and format for page
    for (let i = 0; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;
      formatted += `${this.lastWord(isLast, ' and ')}[${helpers.formatName(
        `${items[i].firstname} ${items[i].lastname}`
      )}](${this.getProfileLink(items[i])})${this.getSeparatorSymbol(isLast)}`;
    }

    return formatted === '' ? null : formatted;
  },
  /**
   * Return an array of emails as a formatted string for display
   *
   * @param {array} emailList
   * @param {number} displayCount
   * @param {boolean} isMasked
   * @param {boolean} isRandom
   * @returns {string|null}
   */
  formatEmailListAsString: function(
    emailList,
    displayCount,
    isMasked,
    isRandom = false,
    useAndSeparator = false
  ) {
    // Early exit there are no emails
    if (!emailList) {
      return null;
    }

    // Get total possible for display
    const totalPossible = this.calculateDisplayMax(emailList, displayCount);

    let formattedEmail = '';
    let items = emailList;
    if (isRandom) {
      items = this.getRandomItems(emailList, totalPossible);
    }

    // Go through each email and format for the page
    for (let i = 0; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;
      const isSecondToLast = i === totalPossible - 2;
      const email =
        typeof items[i] === 'object' ? items[i].full : items[i] || '';
      if (isSecondToLast && useAndSeparator) {
        formattedEmail += this.maskEmail(
          email.toLowerCase(),
          ' and ',
          isMasked
        );
      } else {
        formattedEmail += this.maskEmail(
          email.toLowerCase(),
          this.getSeparatorSymbol(isLast),
          isMasked
        );
      }
    }

    return formattedEmail;
  },

  /**
   * Takes person object, returns title cased full name including middle if applicable
   *
   * @param person
   * @returns {string}
   */
  formatFullName: function(person) {
    const editedMiddlename =
      person.middlename && person.middlename === person.lastname
        ? person.middlename.charAt(0)
        : person.middlename;
    return person.middlename
      ? `${helpers.formatName(person.firstname)} ${helpers.formatName(
          editedMiddlename
        )} ${helpers.formatName(person.lastname)}`
      : `${helpers.formatName(person.firstname)} ${helpers.formatName(
          person.lastname
        )}`;
  },
  /**
   * Return array of phone numbers as single formatted string
   *
   * @param {array} phoneList
   * @param {number} displayCount
   * @param {boolean} isMasked
   * @param {number} exposedDigits
   * @param {boolean} isRandom
   * @returns {string|null}
   */
  formatPhoneNumbersAsString: function(
    phoneList,
    displayCount,
    isMasked,
    exposedDigits,
    isRandom = false
  ) {
    // Early exit if no phone numbers
    if (!phoneList) {
      return null;
    }

    let list = [];

    // Check if object and map to flat array
    if (typeof phoneList[0] === 'object' && phoneList[0].full) {
      list = phoneList.map(({ full }) => full);
    }

    // Calculate total possible to display
    const totalPossible = this.calculateDisplayMax(list, displayCount);

    let formattedNumbers = '';
    let items = list;
    if (isRandom) {
      items = this.getRandomItems(list, totalPossible);
    }

    // Go through each phone number we can display
    for (let i = 0; i < totalPossible; i++) {
      const isLast = i === totalPossible - 1;

      // Format number with masking if required
      formattedNumbers += this.maskPhoneNumber(
        items[i],
        this.getSeparatorSymbol(isLast),
        isMasked,
        exposedDigits
      );
    }

    return formattedNumbers;
  },
  /**
   * Set the profile link for a person
   *
   * @param {object} person
   * @returns {string}
   */
  formatProfileLink: function(person) {
    const city = person.city
      ? resultHelper
          .setTitleCase(person.city.replace(resultHelper.underscoreRegEx, ' '))
          .replace(resultHelper.spaceRegEx, '_')
      : '';

    return `/${helpers.formatName(
      person.firstname,
      '%20'
    )}-${helpers.formatName(person.lastname, '%20')}/${person.state}-${city}/${
      person.id
    }/`;
  },
  /**
   * Return array of relatives as array formatted as [{name: string, url: string}]
   *
   * @param {array} relativesList
   * @param {number} displayCount
   * @returns {array|null}
   */
  formatRelativesArray: function(relativesList, displayCount) {
    // Early exit if there are no relatives
    if (!relativesList || relativesList.length === 0) {
      return null;
    }

    // Calculate total possible for display
    const totalPossible = resultHelper.calculateDisplayMax(
      relativesList,
      displayCount
    );

    const formattedRelatives = [];

    // Go through each relative we can display
    for (let i = 0; i < totalPossible; i++) {
      const firstName = this.setTitleCase(relativesList[i].firstname);
      const lastName = this.setTitleCase(relativesList[i].lastname);
      const city = relativesList[i].city;
      const state = relativesList[i].state;
      const isUnlink = relativesList[i].unlink;
      const firstNameParam = firstName
        .replace(spaceRegEx, '_')
        .replace(dashRegEx, '');
      const lastNameParam = lastName.replace(spaceRegEx, '_');
      // Create object and push to array
      const relative = {
        name: `${firstName} ${lastName}`
      };

      if (!isUnlink) {
        relative['url'] = `/${firstNameParam}-${lastNameParam}/`;
      }

      if (city && state) {
        relative['location'] = `${this.setTitleCase(city)}, ${state}`;

        relative[
          'profile'
        ] = `/${firstNameParam}-${lastNameParam}/${state}-${this.formatName(
          city,
          '_',
          ' '
        )}/${relativesList[i].id}/`;
      }

      formattedRelatives.push(relative);
    }

    return formattedRelatives;
  },
  /**
   * getCheckpeopleCanonicalUrl - builds a url for a Canonical link
   * @param {object} urlSections contains the destructured keys, only firstName and lastName are required
   * @returns {string} - a constructed prod URL
   */
  getCheckpeopleCanonicalUrl: function(urlSections) {
    const { firstName, lastName, page, city, stateAbbr } = urlSections;
    let url = `https://checkpeople.com/name/${firstName}-${lastName}`;

    if (stateAbbr) {
      url += `/in-${stateAbbr}`;
    }
    if (city) {
      url += `/${city}`;
    }

    if (page > 1) {
      url += `/${page}`;
    }

    return url;
  },
  /*
   *
   * @param {array} phonesList
   * @param {number} displayCount
   * @param {string} currentPhoneNumber
   * @returns {array|null}
   */
  formatPhonesArray: function(phonesList, displayCount, currentPhoneNumber) {
    // Early exit if there are no phone numbers
    if (!phonesList || phonesList.length === 0) {
      return null;
    }

    // Calculate total possible for display
    const totalPossible = resultHelper.calculateDisplayMax(
      phonesList,
      displayCount
    );

    const formattedPhones = [];

    // Go through each phone number we can display
    for (let i = 0; i < totalPossible; i++) {
      const formattedPhoneNumber = this.formatPhoneNumber(phonesList[i].full);
      const rawPhoneNumber = this.dashedPhoneNumber(phonesList[i].full);

      const phone = {
        number: formattedPhoneNumber
      };

      // link unique phone numbers, not duplicates
      if (rawPhoneNumber !== currentPhoneNumber) {
        phone['url'] = `/phone/${rawPhoneNumber}/`;
      }

      formattedPhones.push(phone);
    }

    return formattedPhones;
  },
  /**
   * Return standard ages formatted for select
   *
   * @returns {[{name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, {name: string, value: string}, null]}
   */
  getAgeOptions: function() {
    return [
      {
        value: '',
        name: 'All Ages'
      },
      {
        value: '18-30',
        name: '18-30'
      },
      {
        value: '31-40',
        name: '31-40'
      },
      {
        value: '41-50',
        name: '41-50'
      },
      {
        value: '51-60',
        name: '51-60'
      },
      {
        value: '61-110',
        name: '61-110'
      }
    ];
  },
  /**
   * Get standard params for Channel Search
   *
   * @returns {{max_age: number, max_relatives: number, max_emails: number, max_aliases: number, max_addresses: number, min_age: number, max_phones: number}}
   */
  getChannelParams: function() {
    return {
      min_age: 1,
      max_age: 120,
      max_aliases: 0,
      max_addresses: 0,
      max_relatives: 0,
      max_emails: 0,
      max_phones: 0
    };
  },
  /**
   * Return the count prefix string
   *
   * @returns {string}
   */
  getCountPrefix: function() {
    return '(';
  },
  /**
   * Return the count suffix string
   *
   * @returns {string}
   */
  getCountSuffix: function() {
    return ')';
  },
  /**
   * Returns the filter list for LocalPeopleFull api calls on SEO Results
   *
   * @returns {string}
   */
  getFiltersForLocalPeopleFull: function() {
    return 'id,cid,city,state,firstname,lastname,middlename,dob,aliases,phones,relatives,zip,street_name,street_type,street_number,street_affix,street_physical,addresses,crimes,optout,employers,emails,has_emails,has_employers,has_phones,has_relatives,has_social_media';
  },
  /**
   * Returns the filter list for Optout Request api calls
   *
   * @returns {string}
   */
  getFiltersForOptouts: function() {
    return 'id,cid,city,state,firstname,lastname,middlename,dob,aliases,addresses,relatives,zip,optout';
  },
  /**
   * Returns a formatted string of addresses for optout results
   *
   * @param {array} addressList
   * @param {number|undefined} count
   * @param {string} separator
   * @returns {*}
   */
  getFormattedOptoutAddressHistory: function(
    addressList,
    count = 6,
    separator
  ) {
    return addressList
      .map(address => {
        return `${this.formatName(address.city)},  ${address.state}`;
      })
      .slice(0, count)
      .join(separator);
  },
  /**
   * Returns formatted string of aliases for optout results
   *
   * @param {array} aliasList
   * @param {number|undefined} count
   * @param {string} separator
   * @returns {*}
   */
  getFormattedOptoutAliasList: function(aliasList, count = 6, separator) {
    if (typeof aliasList[0] === 'string') {
      return aliasList
        .map(alias => {
          return `${this.formatName(alias)}`;
        })
        .slice(0, count)
        .join(separator);
    }
    return aliasList
      .map(alias => {
        const firstName = alias.firstname;
        const middleName = alias.middlename || '';
        const lastName = alias.lastname;

        return `${this.formatName(firstName)} ${middleName &&
          this.formatName(middleName)} ${this.formatName(lastName)}`;
      })
      .slice(0, count)
      .join(separator);
  },
  /**
   * Returns formatted string of relatives for optouts
   *
   * @param {array} relativesList
   * @param {number|undefined} count
   * @param {string} separator
   * @returns {string}
   */
  getFormattedOptoutRelatives: function(relativesList, count = 6, separator) {
    const totalRelatives =
      relativesList.length <= count ? relativesList.length : count;
    let formattedRelatives = '';

    // Go through each relative we can display
    for (let i = 0; i < totalRelatives; i++) {
      const isLast = i === relativesList.length - 1;
      const firstName = this.formatName(relativesList[i].firstname);
      const lastName = this.formatName(relativesList[i].lastname);
      formattedRelatives += `${firstName} ${lastName}${
        !isLast ? separator : ''
      }`;
    }

    return formattedRelatives;
  },
  /**
   * Format names in verified results, return array of objects for tz-premium-info
   *
   * @param {array} results
   * @param {number|null} max
   * @returns {[{age: number, city: string, firstName: string, middleName: string|null, lastName: string, state: string}]}
   */
  getFormattedVerifiedResults: function(results, max) {
    let verifiedData = [];
    const totalMax = max ? max : results.length;
    for (let i = 0; i < totalMax; i++) {
      if (results[i] && !this.optOutCids.includes(results[i].cid)) {
        verifiedData.push({
          cid: results[i].cid,
          age: results[i].age,
          city: helpers.formatName(results[i].city),
          firstName: helpers.formatName(results[i].firstname),
          middleName: results[i].middlename
            ? helpers.formatName(results[i].middlename)
            : null,
          lastName: helpers.formatName(results[i].lastname),
          state: results[i].state
        });
      }
    }

    return verifiedData;
  },
  /**
   * Return a string with requested number of masking symbols
   *
   * @param {number} number
   * @returns {string}
   */
  getMaskingString: function(number) {
    let finalString = '';

    for (let i = 0; i < number; i++) {
      finalString += symbols.masking;
    }

    return finalString;
  },
  /**
   * Return mobile media query event for standard mobile max (768px)
   *
   * @returns {boolean|MediaQueryList}
   */
  getMobileQuery: function(width = 768) {
    // Ensure we do not error if method called before window object exists
    if (typeof window !== 'undefined') {
      return window.matchMedia(`(max-width: ${width}px)`);
    }

    return false;
  },
  getDesktopQuery: function(width = 769) {
    // Ensure we do not error if method called before window object exists
    if (typeof window !== 'undefined') {
      return window.matchMedia(`(min-width: ${width}px)`);
    }

    return false;
  },
  /**
   * Returns the meta list for the page based on route name
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @returns {*}
   */
  getSEOMetaData: function(routeName, pageCMS) {
    switch (routeName) {
      case 'seo.city':
        return pageCMS.meta_city;
      case 'seo.state':
        return pageCMS.meta_state;
      default:
        return pageCMS.meta_root;
    }
  },
  /**
   * Returns the unformatted title based upon route name
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @returns {string}
   */
  getSEOMetaTitleText: function(routeName, pageCMS) {
    switch (routeName) {
      case 'seo.city':
        return pageCMS.page_title_city;
      case 'seo.state':
        return pageCMS.page_title_state;
      default:
        return pageCMS.page_title_root;
    }
  },
  /**
   * Returns formatted meta data for profile page
   *
   * @param {object} pageCMS
   * @param {object} data
   * @returns {*[]}
   */
  getSEOProfileMetaFormatted: function(pageCMS, data) {
    const transformedMeta = [];

    for (let i = 0; i < pageCMS.length; i++) {
      const metaCopy = Object.assign({}, pageCMS[i]);
      const { content } = metaCopy;
      metaCopy.content = NBars.transform(content, data);
      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Get the formatted meta data for city pages
   *
   * @param {string} city
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} pageMeta
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @returns {*[]}
   */
  getSEOResultsCityMetaFormatted: function(
    city,
    firstName,
    lastName,
    pageMeta,
    page,
    state,
    stateAbbr,
    count = null
  ) {
    const transformedMeta = [];
    const formattedCity = city.includes(' ') ? city.split(' ').join('-') : city;

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            city,
            firstName,
            lastName,
            page,
            stateAbbr,
            count
          });
          break;
        case 'keywords':
        case 'url':
          metaCopy.content = NBars.transform(content, {
            city,
            firstName,
            lastName,
            stateAbbr,
            state
          });
          break;
        case 'og:url': {
          metaCopy.content = NBars.transform(content, {
            city: formattedCity,
            firstName,
            lastName,
            stateAbbr,
            state
          });
        }
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the city title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} city
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @returns {string}
   */
  getSEOResultsCityTitleFormatted: function(
    routeName,
    pageCMS,
    city,
    firstName,
    lastName,
    page,
    state,
    stateAbbr,
    count = null
  ) {
    return NBars.transform(this.getSEOMetaTitleText(routeName, pageCMS), {
      city,
      firstName,
      lastName,
      page,
      state,
      stateAbbr,
      count
    });
  },
  /**
   * Return formatted meta list for seo root results
   *
   * @param {string} firstName
   * @param {string} lastName
   * @param {number} limit
   * @param {array} locationList
   * @param {object} pageMeta
   * @param {string} page
   * @param {number} totalPeople
   * @param {object} metaAlternatives
   * @returns {*[]}
   */
  getSEOResultsRootMetaFormatted: function(
    firstName,
    lastName,
    limit,
    locationList,
    pageMeta,
    page,
    totalPeople,
    metaAlternatives
  ) {
    const transformedMeta = [];

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          const hasOnlyTwoLocations = locationList.length === 2;
          if (
            hasOnlyTwoLocations &&
            metaAlternatives &&
            metaAlternatives.description
          ) {
            const topTwoLocations = locationList
              .sort((a, b) => (a.count < b.count ? 1 : -1))
              .slice(0, 2);

            metaCopy.content = NBars.transform(metaAlternatives.description, {
              firstName: firstName,
              lastName: lastName,
              page,
              state1: topTwoLocations[0].state_full,
              state2: topTwoLocations[1].state_full
            });
          } else {
            metaCopy.content = NBars.transform(content, {
              firstName: firstName,
              lastName: lastName,
              page,
              stateCount: locationList.length,
              statePlural: locationList.length > 1 ? 'states' : 'state'
            });
          }
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName,
            lastName: lastName,
            page,
            count: totalPeople
          });
          break;
        case 'keywords':
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            firstName: firstName,
            lastName: lastName
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Returns the transformed string for the root title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @returns {string}
   */
  getSEOResultsRootTitleFormatted: function(
    routeName,
    pageCMS,
    firstName,
    lastName,
    count,
    page
  ) {
    return NBars.transform(this.getSEOMetaTitleText(routeName, pageCMS), {
      firstName,
      lastName,
      count,
      page
    });
  },
  /**
   * Returns the transformed string for the state title
   *
   * @param {string} routeName
   * @param {object} pageCMS
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} page
   * @param {string} state
   * @param {string} stateAbbr
   * @returns {string}
   */
  getSEOResultsStateTitleFormatted: function(
    routeName,
    pageCMS,
    firstName,
    lastName,
    page,
    state,
    stateAbbr,
    count = null
  ) {
    return NBars.transform(this.getSEOMetaTitleText(routeName, pageCMS), {
      firstName,
      lastName,
      page,
      state,
      stateAbbr,
      count
    });
  },
  /**
   * Returns formatted meta list for SEO state results
   *
   * @param {number} cityCount
   * @param {string} firstName
   * @param {string} lastName
   * @param {object} pageMeta
   * @param {string} page
   * @param {string} stateAbbr
   * @param {string} state
   * @returns {*[]}
   */
  getSEOResultsStateMetaFormatted: function(
    cityCount,
    firstName,
    lastName,
    pageMeta,
    page,
    stateAbbr,
    state,
    count = null
  ) {
    const transformedMeta = [];

    for (let i = 0; i < pageMeta.length; i++) {
      const metaCopy = Object.assign({}, pageMeta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          const cityOrCities = cityCount === 1 ? 'city' : 'cities';
          metaCopy.content = NBars.transform(content, {
            cityCount,
            firstName,
            lastName,
            page,
            stateAbbr,
            state,
            cityOrCities
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName,
            page,
            stateAbbr,
            state,
            count
          });
          break;
        case 'keywords':
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            firstName,
            lastName,
            stateAbbr,
            state
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return transformedMeta;
  },
  /**
   * Return structured data for ld+json
   *
   * @param {string} addresses
   * @param {string} aliases
   * @param {string} fullName
   * @param {string} phoneNumbers
   * @param {string} profileLink
   * @param {string} relationships
   * @returns {{"@type": string, homeLocation: [], name: string, telephone: [], "@context": string, additionalName: [], url: string, relatedTo: []}}
   */
  getPersonSchema: function(
    addresses,
    aliases,
    fullName,
    phoneNumbers,
    profileLink,
    relationships
  ) {
    relationships = relationships
      ? relationships.map(relative => {
          relative['@type'] = 'Person';
          relative.url = `https://${process.env.website}.com${relative.url}`;

          if (relative.profile) {
            // If there is a url and profile, use the profile url instead
            if (relative.url && relative.profile) {
              relative.url = relative.profile;
            }
            // Person cannot have profile in object when ld+json
            delete relative.profile;
          }

          // Person cannot have location on object when ld+json
          if (relative.location) {
            delete relative.location;
          }

          return relative;
        })
      : [];
    aliases = aliases
      ? aliases.split(symbols.general).map(item => item.trim())
      : [];
    phoneNumbers = phoneNumbers
      ? phoneNumbers.split(symbols.general).map(item => item.trim())
      : [];
    addresses = addresses
      ? addresses.split(symbols.address).map(address => {
          const addressParts = address
            .trim()
            .split(symbols.general)
            .map(item => item.trim());
          const noStreet = addressParts.length === 2;
          const stateZip = noStreet ? addressParts[1] : addressParts[2];
          const stateZipParts = stateZip ? stateZip.split(' ') : [];
          return {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              '@id': '',
              url: '',
              description: '',
              streetAddress: noStreet ? '' : addressParts[0],
              addressLocality: noStreet ? addressParts[0] : addressParts[1],
              addressRegion: stateZipParts[0] ? stateZipParts[0] : '',
              postalCode: stateZipParts[1] ? stateZipParts[1] : ''
            }
          };
        })
      : [];
    return {
      '@context': 'http://schema.org',
      '@type': 'Person',
      url: profileLink,
      name: fullName,
      additionalName: aliases,
      homeLocation: addresses,
      telephone: phoneNumbers,
      relatedTo: relationships
    };
  },
  /**
   * Return a random selection of relatives with full name and location formatted for display, id and city formatted for url
   *
   * @param {object} people
   * @returns {Array|null}
   */
  getRelativesFormattedForSection: function(people) {
    const setRelatives = [];
    // Get a randomized list of relatives
    const relatives = this.getRandomRelatives(people);
    if (relatives) {
      // Go through each relative object
      for (let i = 0; i < relatives.length; i++) {
        const city = this.setTitleCase(relatives[i].city).replace(/ /g, '-');
        const person = {
          // Add formatted name and location
          firstname: this.setTitleCase(relatives[i].firstname),
          lastname: this.setTitleCase(relatives[i].lastname),
          fullName: this.formatFullName(relatives[i]),
          fullLocation: `${city}, ${relatives[i].state}`,
          id: relatives[i].id || relatives[i].relative_id,
          // Add dashes instead of spaces in city
          city: city.replace(spaceRegEx, '-'),
          state: relatives[i].state,
          cid: relatives[i].cid || null
        };
        setRelatives.push(person);
      }
    }

    // Return the list
    return setRelatives;
  },
  /**
   * Return object containing formatted names for display and requests
   *
   * @param params
   * @returns {{firstName: (*|string), lastName: (*|string), firstNameRequestFormat: string, lastNameRequestFormat: string}}
   */
  getResultNames: function(params) {
    const firstName = this.setTitleCase(
      this.parseParamFirstName(params.firstName.toLowerCase())
    );
    const lastName = this.setTitleCase(
      this.parseParamLastName(params.lastName)
    );
    const firstNameRequestFormat = firstName
      .toUpperCase()
      .replace(this.spaceRegEx, '%20');
    const lastNameRequestFormat = lastName
      .toUpperCase()
      .replace(this.spaceRegEx, '%20');

    return {
      firstName,
      lastName,
      firstNameRequestFormat,
      lastNameRequestFormat
    };
  },
  /**
   * Return an array formatted for the selected locations
   *
   * @param {string} allText
   * @param {string} displayName
   * @param {number | null} count
   * @param {string} url
   * @param {string | undefined} allUrl - all text url string
   * NOTE: allUrl is defaulted to undefined to signal its optional to eslint
   * @returns {[{displayName: string, urlValue: string | undefined}, {displayName: string, urlValue: string, count: number}]}
   */
  getSelectedLocationArray: function(
    allText,
    displayName,
    count,
    url,
    allUrl = undefined
  ) {
    return [
      {
        displayName: allText,
        urlValue: allUrl
      },
      {
        displayName: displayName,
        count: count,
        urlValue: url
      }
    ];
  },
  // Return separator symbol
  getSeparatorSymbol: function(isLast, isAddress) {
    // If last item, no separator, otherwise use correct separator symbol
    return isLast ? '' : (isAddress ? symbols.address : symbols.general) + ' ';
  },
  // Return final word
  lastWord: function(isLast, word) {
    // If last item, display word
    return isLast ? word : '';
  },
  /**
   * Returns a formatted list of table of contents items for tzTocList
   *
   * @param {array} people
   * @param {array} tocCms
   * @returns {null}
   */
  getTocList: function(people, tocCms) {
    if (!people || !tocCms) {
      return null;
    }

    const sumAll = (previousValue, currentValue) =>
      previousValue + currentValue;

    function getInclusiveRandomNumberBetween(min, max) {
      return Math.floor(
        Math.random() * (Math.floor(max) - Math.ceil(min) + 1) + min
      );
    }

    const addressCount = [...people]
      .filter(person => (person.addresses ? person.addresses.length : null))
      .map(person => {
        return person.addresses.length;
      })
      .reduce(sumAll, 0);
    const emailCount = [...people]
      .map(person => {
        return person.has_emails;
      })
      .reduce(sumAll, 0);
    const employerCount = [...people]
      .map(person => person.has_employers)
      .reduce(sumAll, 0);
    const phoneCount = [...people]
      .map(person => person.has_phones)
      .reduce(sumAll, 0);
    const relativesCount = [...people]
      .map(person => person.has_relatives)
      .reduce(sumAll, 0);
    const socialCount = [...people]
      .map(person => person.has_social_media)
      .reduce(sumAll, 0);
    const crimesCount = [...people]
      .map(person => (person.has_crimes ? person.has_crimes : 0))
      .reduce(sumAll, 0);

    const counts = {
      addresses:
        addressCount > 15
          ? getInclusiveRandomNumberBetween(13, 15)
          : addressCount,
      'business-employment':
        employerCount > 6
          ? getInclusiveRandomNumberBetween(3, 6)
          : employerCount,
      'email-addresses':
        emailCount > 7 ? getInclusiveRandomNumberBetween(4, 7) : emailCount,
      'phone-numbers':
        phoneCount > 7 ? getInclusiveRandomNumberBetween(5, 7) : phoneCount,
      'relatives-associates':
        relativesCount > 35
          ? getInclusiveRandomNumberBetween(30, 35)
          : relativesCount,
      // If there's no count for social media profiles, default count should be two(facebook and linkedin)
      'social-media-profiles':
        socialCount === 0
          ? 2
          : socialCount > 22
          ? getInclusiveRandomNumberBetween(18, 22)
          : socialCount,
      'criminal-records': crimesCount
    };

    for (let i = 0; i < tocCms.length; i++) {
      // links that only show an icon still need to be checked for a count to show/hide link
      if (tocCms[i].image) {
        tocCms[i].hide = counts[tocCms[i].anchor] === 0;
      }
      if (!tocCms[i].image) {
        tocCms[i].count = counts[tocCms[i].anchor];
      }
    }

    return tocCms;
  },
  /**
   * Return masked email address
   *
   * @param {string} email
   * @param {string} separator
   * @param {boolean} isMasked
   * @returns {string}
   */
  maskEmail: function(email, separator, isMasked) {
    // No masking then display to user as is
    if (!isMasked) {
      return email + separator;
    }
    // Otherwise return first three characters, masking string, and everything from @symbol on
    return (
      email.slice(0, 3) +
      this.getMaskingString(4) +
      email.slice(email.indexOf('@')) +
      separator
    );
  },
  /**
   * Return masked phone number
   *
   * @param {string|number} phone
   * @param {string} separator
   * @param {boolean} isMasked
   * @param {number} exposedDigits
   * @returns {string}
   */
  maskPhoneNumber: function(phone, separator, isMasked, exposedDigits) {
    // Ensure phone is string
    phone = '' + phone;

    // Grab all the exposed digits first
    let formattedNumber = isMasked ? phone.slice(0, exposedDigits) : phone;

    // If the digits should be masked, mask it, and append all to formatted number
    if (isMasked) {
      formattedNumber += phone
        .slice(exposedDigits)
        .replace(/\d/g, symbols.masking);
    }

    // Now make the number pretty for our users
    formattedNumber =
      '(' +
      formattedNumber.slice(0, 3) +
      ') ' +
      formattedNumber.slice(3, 6) +
      '-' +
      formattedNumber.slice(6) +
      separator;

    return formattedNumber;
  },
  /**
   * Parses a first name into param format
   * @param {string} firstName
   * @returns {string}
   */
  parseAsParamFirstName: function(firstName) {
    if (!firstName) return;
    let firstNameLowerCase = firstName.toLowerCase();
    let firstNameResult = '';

    for (let i = 0; i < firstName.length; i++) {
      if (firstName[i] === ' ') {
        firstNameResult = firstNameResult + '_';
      } else if (firstName[i] !== firstNameLowerCase[i]) {
        firstNameResult =
          i === 0 || firstName[i - 1] === '-'
            ? firstNameResult + firstName[i]
            : firstNameResult + '_' + firstName[i];
      } else {
        firstNameResult = firstNameResult + firstNameLowerCase[i];
      }
    }

    return firstNameResult;
  },
  /**
   * Parses the last name into param format
   * @param {string} lastName
   * @returns {string}
   */
  parseAsParamLastName: function(lastName) {
    if (!lastName) return;
    let lastNameResult = '';
    for (let i = 0; i < lastName.length; i++) {
      if (lastName[i] === ' ') {
        lastNameResult = lastNameResult + '_';
      } else {
        lastNameResult = lastNameResult + lastName[i];
      }
    }
    return lastNameResult;
  },
  /**
   * Parses the first name param into a readable format
   * @param {string} firstName
   * @returns {string}
   */
  parseParamFirstName: function(firstName) {
    if (!firstName) return;
    let firstNameLowerCase = firstName.toLowerCase();
    let firstNameResult = '';
    for (let i = 0; i < firstName.length; i++) {
      if (firstName[i] === '_' || firstName[i] === '+') {
        firstNameResult = firstNameResult + ' ';
      } else if (
        firstName[i] !== firstNameLowerCase[i] &&
        firstName[i] !== '.'
      ) {
        firstNameResult =
          i === 0 || firstName[i - 1] === '_'
            ? firstNameResult + firstName[i]
            : firstNameResult + '-' + firstName[i];
      } else {
        firstNameResult = firstNameResult + firstNameLowerCase[i];
      }
    }
    // Return first name and ensure single spacing
    return firstNameResult.replace(spaceRegEx, ' ');
  },
  /**
   * Parses the last name param into a readable format
   * @param {string} lastName
   * @returns {string}
   */
  parseParamLastName: function(lastName) {
    if (!lastName) return;
    let lastNameResult = '';
    for (let i = 0; i < lastName.length; i++) {
      if (lastName[i] === '_' || lastName[i] === '+') {
        lastNameResult = lastNameResult + ' ';
      } else {
        lastNameResult = lastNameResult + lastName[i];
      }
    }
    // Return last name and ensure single spacing
    return lastNameResult.replace(/  +/g, ' ');
  },
  /**
   * Sets the location list for root level results
   * @param peopleResponse
   * @param locationList
   * @returns {{locationList, searchFilterStateList}}
   */
  setLocationList(peopleResponse, locationList) {
    let stateList = [];
    let searchFilterStateList = [];
    let stateCount = {};

    peopleResponse.forEach(function(person) {
      if (!stateList.includes(person.state)) {
        stateList.push(person.state);
        stateCount[person.state] = 1;
      } else {
        stateCount[person.state] += 1;
      }
    });

    for (let i = 0; i < locationList.length; i++) {
      if (!locationList[i].state_full) {
        continue;
      }
      const stateParam = this.getStateFromAbbr(locationList[i].state).replace(
        / /g,
        '-'
      );
      locationList[i]['name'] = locationList[i].state_full;
      const urlValue =
        process.env.website === 'checkpeople'
          ? `/in-${locationList[i].state}`
          : process.env.website === 'information'
          ? `/${stateParam.toLowerCase()}/`
          : `${locationList[i].state}/`;
      searchFilterStateList.push({
        displayName: locationList[i].state_full,
        urlValue,
        count: locationList[i].count
      });
    }

    return {
      locationList,
      searchFilterStateList
    };
  },
  /**
   * Returns people data with age
   * @param people
   * @returns {*}
   */
  setPeopleAges(people) {
    const currentDate = new Date();
    for (let i = 0; i < people.length; i++) {
      let birthDate = people[i].dob;
      if (birthDate === '1600-01-01') {
        people[i].age = null;
      } else {
        birthDate = new Date(birthDate);
        people[i].age = Math.floor((currentDate - birthDate) / 31556952000);
      }
    }
    return people;
  },
  /**
   * Returns Crime data for the crime component
   * @param crimes
   * @returns {{criminalRecordsSexOffenderCount, criminalRecords}}
   */
  setCrimes(crimes, limit = 3, onlyCrimes = true) {
    let criminalRecords = [];
    let criminalRecordsSexOffenderCount = 0;
    for (let i = 0; i < crimes.length; i++) {
      const personCrimes = crimes[i].crimes || [];
      let isSexOffender = false;

      for (let crime = 0; crime < personCrimes.length; crime++) {
        const { sexoffender, crimetype, description } = personCrimes[crime];
        if (sexoffender) {
          isSexOffender = true;
        }

        if (!crimetype && !description) {
          continue;
        }

        if (onlyCrimes) {
          criminalRecords.push(personCrimes[crime]);
        } else {
          const person = {
            firstname: crimes[i].firstname,
            middlename: crimes[i].middlename,
            lastname: crimes[i].lastname,
            crime: personCrimes[crime]
          };
          criminalRecords.push(person);
        }
      }

      if (isSexOffender) {
        criminalRecordsSexOffenderCount++;
      }
    }

    // Randomly select limited crimes
    const limitedCrimes = this.getRandomItems(criminalRecords, limit);

    return { limitedCrimes, criminalRecordsSexOffenderCount };
  },
  socialMediaMapper(platform) {
    // Only small change is to ignore facebook_id, google plus since it doesn't exist anymore
    // From: https://gitlab.com/trazi-ventures/white-label/utils/data-masher/-/blob/master/parser/utils/social-urls.js
    const platforms = Object.entries({
      ABOUT: 'about.me/:USERNAME',
      AMAZON: 'www.amazon.com/gp/pdp/profile/:USERNAME',
      BADOO: 'badoo.com/:USERNAME',
      BEBO: 'www.bebo.com/Profile.jsp?MemberId=:USERNAME',
      BREAK: 'www.break.com/:USERNAME',
      BUZZNET: ':USERNAME.buzznet.com/user/profile',
      CA: 'www.amazon.ca/registry/wishlist/:USERNAME',
      CARE2: 'www.care2.com/c2c/people/profile.html?pid=:USERNAME',
      CO: 'angel.co/:USERNAME',
      DAILYMOTION: 'www.dailymotion.com/:USERNAME',
      DATE:
        'sacramento.date.com/GetFullProfile.do?other_profile_id=:USERNAME&no_user=rso',
      DATEHOOKUP: 'www.datehookup.com/User-:USERNAME.htm',
      DE:
        'www.stayfriends.de/h/:USERNAME/location/school1/school2/school/name.html',
      DIGG: 'digg.com/users/:USERNAME',
      DISQUS: 'disqus.com/:USERNAME/#main',
      EBAY: 'www.ebay.com/usr/:USERNAME',
      FACEBOOK: 'www.facebook.com/:USERNAME',
      //FACEBOOK_ID: 'www.facebook.com/people/_/:USERNAME',
      FACELINK: 'www.facelink.com/link.php?uname=:USERNAME',
      FLICKR: 'www.flickr.com/people/:USERNAME',
      FLIXSTER: 'www.flixster.com/user/:USERNAME',
      FM: 'www.last.fm/user/:USERNAME',
      FOTKI: 'members.fotki.com/:USERNAME/about/',
      FOTOLOG: 'www.fotolog.com/:USERNAME/',
      FOURSQUARE: 'foursquare.com/:USERNAME',
      FRIENDFINDER: 'friendfinder.com/p/:USERNAME.cgi',
      FRIENDSTER: 'profiles.friendster.com/:USERNAME',
      GAIAONLINE: 'www.gaiaonline.com/profiles/:USERNAME',
      GOODREADS: 'www.goodreads.com/user/show/:USERNAME',
      //GOOGLE: 'plus.google.com/:USERNAME',
      GRAVATAR: 'en.grvatar.com/:USERNAME',
      HABBO: 'www.habbo.com/home/:USERNAME',
      HI5: 'www.hi5.com/friend/:USERNAME--profile--html',
      HUBPAGES: ':USERNAME.hubpages.com/',
      IGN: 'people.ign.com/:USERNAME',
      IMEEM: 'www.imeem.com/people/:USERNAME',
      INSTAGRAM: 'www.instagram.com/:USERNAME',
      JOOST: 'www.joost.com/users/:USERNAME',
      KLOUT: 'klout.com/:USERNAME',
      KONGREGATE: 'www.kongregate.com/accounts/:USERNAME',
      LAST: 'www.last.fm/user/:USERNAME',
      LINKEDIN: 'www.linkedin.com/in/:USERNAME',
      LIVE: ':USERNAME.profile.live.com',
      MEETME: 'www.meetme.com/member/:USERNAME',
      MEETUP: 'www.meetup.com/members/8223105/:USERNAME',
      METACAFE: 'new.metacafe.com/channels/:USERNAME/',
      MYSPACE:
        'comment.myspace.com/index.cfm?fuseaction=user.viewComments&friendID=:USERNAME',
      NAPPYBOYONLINE: 'www.nappyboyonline.com/profile/:USERNAME',
      NAVYDADS: 'www.navydads.com/profile/:USERNAME',
      NET: 'www.zedge.net/profile/:USERNAME',
      NETLOG: 'en.netlog.com/:USERNAME',
      NING: 'fetlerenglish.ning.com/profile/:USERNAME',
      PBASE: 'www.pbase.com/:USERNAME/profile',
      PERFSPOT: 'www.perfspot.com/profile.asp?uid=:USERNAME',
      PHOTOBUCKET: 'beta.photobucket.com/user/:USERNAME/profile/',
      PINTEREST: 'pinterest.com/:USERNAME',
      PLENTYOFFISH:
        'www.plentyoffish.com/viewprofile.aspx?profile_id=:USERNAME',
      PLURK: 'www.plurk.com/:USERNAME',
      PREFSPOT: 'www.prefspot.com/profile.asp?uid=:USERNAME',
      QIK: 'qik.com/:USERNAME',
      QUORA: 'www.quora.com/profile/:USERNAME',
      RATEMYPROFESSORS:
        'www.ratemyprofessors.com/ShowRatings.jsp?tid=:USERNAME',
      RECRUITINGBLOGS: 'www.recruitingblogs.com/profile/:USERNAME',
      REDDIT: 'www.reddit.com/user/:USERNAME/',
      REDIFF: 'mypage.rediff.com/-/:USERNAME',
      REVERBNATION: 'www.reverbnation.com/:USERNAME',
      RU: 'vkontakte.ru/:USERNAME',
      SCRIBD: 'www.scribd.com/:USERNAME',
      SKRILLAGORILLAS: 'www.skrillagorillas.com/profile/:USERNAME',
      SKYROCK: ':USERNAME.skyrock.com/profil/',
      SONICO: 'www.sonico.com/u/:USERNAME',
      SOUNDCLOUD: 'www.soundcloud.com/:USERNAME',
      STICKAM: 'www.stickam.com/:USERNAME',
      STUMBLEUPON: 'www.stumbleupon.com/stumbler/:USERNAME/',
      TAGGED: 'www.tagged.com/mypage.html?uid=:USERNAME',
      TOOLBOX: 'it.toolbox.com/people/:USERNAME/',
      TRIPADVISOR: 'www.tripadvisor.com/members/:USERNAME',
      TRUE:
        'www.true.com/view_profile_allinone.htm?V7=1&exitpop=no&uid=:USERNAME',
      TUMBLR: ':USERNAME.tumblr.com/',
      TV: 'www.ustream.tv/user/:USERNAME/info-stats',
      TWICSY: 'twicsy.com/u/:USERNAME',
      TWITTER: 'www.twitter.com/:USERNAME',
      UK:
        'www.friendsreuniteddating.co.uk/join.asp?wci=regedit_viewmember&member_key=:USERNAME',
      VAMPIREFREAKS: 'vampirefreaks.com/:USERNAME',
      VARIETY:
        'www.variety.com/profiles/people/main/:USERNAME/%2520.html?dataSet=1',
      VEOH: 'www.veoh.com/users/:USERNAME',
      VIMEO: 'vimeo.com/:USERNAME',
      VK: 'vk.com/id:USERNAME',
      WAYN: 'www.wayn.com/profiles/:USERNAME',
      WEBSHOTS: 'community.webshots.com/user/:USERNAME/profile',
      WSSCMIAMI: 'www.wsscmiami.com/profile/:USERNAME',
      XANGA: 'profile.xanga.com/:USERNAME',
      YAHOO: 'profile.yahoo.com/:USERNAME',
      YELP: 'www.yelp.com/user_details?userid=:USERNAME',
      YOURACREATOR: 'www.youracreator.com/profile/:USERNAME',
      YOUTUBE: 'www.youtube.com/user/:USERNAME',
      ZEDGE: 'www.zedge.net/profile/:USERNAME'
    });

    const platformRegex = RegExp(platform);
    let mappedValue = null;
    for (let i = 0; i < platforms.length; i++) {
      const [key, value] = platforms[i];

      if (platformRegex.test(key)) {
        mappedValue = key.toLowerCase();
        mappedValue =
          mappedValue.charAt(0).toUpperCase() + mappedValue.slice(1);
        // Transform Linkedin to LinkedIn since they are fancy
        if (mappedValue === 'Linkedin') {
          mappedValue = 'LinkedIn';
        }
        break;
      }
    }

    return mappedValue;
  },
  /**
   * @param {array} people
   * @param {number} amount
   * @returns {array|null}
   */
  getRandomRelatives(people, amount = 6) {
    const relatives = [];
    // Go through each person in array
    people.forEach(person => {
      // Early exit if no relative
      if (!(person.relatives && person.relatives.length > 0)) {
        return;
      }

      // Go through each relative
      for (let i = 0; i < person.relatives.length; i++) {
        const relative = person.relatives[i];
        // If the relative is in a standard US State and has first and last name, push them to the relatives array
        if (
          relative.firstname &&
          relative.lastname &&
          this.getStateFromAbbr(relative.state)
        ) {
          relatives.push(relative);
        }
      }
    });

    // If there are no relatives in the US, return nothing
    if (relatives.length === 0) {
      return null;
    }

    // Otherwise randomly pull the indicated amount of of relatives in an array
    return relatives.sort(() => 0.5 - Math.random()).slice(0, amount);
  },
  setSocialMediaData(
    data,
    firstName,
    lastName,
    displayMax = null,
    displayMultipleOfEachSocialMediaType = false
  ) {
    const stripTrailingSlash = str => {
      return str.endsWith('/') ? str.slice(0, -1) : str;
    };
    const umSocialIcons = [
      'indeed',
      'foursquare',
      'reddit',
      'instagram',
      'github',
      'soundcloud',
      'aboutme',
      'gitlab',
      'stackoverflow',
      'angellist',
      'gravatar',
      'twitter',
      'behance',
      'klout',
      'vimeo',
      'crunchbase',
      'linkedin',
      'wordpress',
      'meetup',
      'xing',
      'dribbble',
      'myspace',
      'youtube',
      'facebook',
      'pinterest',
      'flicker',
      'quora',
      'social'
    ];
    const cpSocialIcons = [
      'amazon',
      'behance',
      'digg',
      'dribbble',
      'ebay',
      'facebook',
      'flickr',
      'flixster',
      'foursquare',
      'github',
      'googleplus',
      'hi5',
      'imeem',
      'instagram',
      'klout',
      'last',
      'line',
      'linkedin',
      'medium',
      'messenger',
      'myspace',
      'pinterest',
      'skype',
      'snapchat',
      'soundcloud',
      'spotify',
      'telegram',
      'tiktok',
      'twitter',
      'website',
      'whatsapp',
      'youtube'
    ];
    const socialIcons =
      process.env.website === 'checkpeople' ? cpSocialIcons : umSocialIcons;

    const oneOfEachSocialProfilesObj = {};
    const multipleOfEachSocialProfilesArr = [];

    // Loop through data
    for (let i = 0; i < data.length; i++) {
      const person = data[i];

      // Skip over people who don't have social media
      if (!(person.social_media && person.social_media.length > 0)) {
        continue;
      }

      // Loop through social media
      for (let x = 0; x < person.social_media.length; x++) {
        const social = person.social_media[x];

        const key = this.socialMediaMapper(social.platform);

        // Skip anything that wasn't matched with the mapper
        if (!key) {
          continue;
        }
        const socialIconKey = key.toLowerCase();
        // Figure out if there's an icon we can use for the platform if not fallback to social
        const platformName = socialIcons.includes(socialIconKey)
          ? socialIconKey
          : 'social';
        // Figure out display name (api response doesn't give us this)
        let subText = null;
        if (
          key === 'Facebook' ||
          key === 'Twitter' ||
          key === 'Youtube' ||
          key === 'Klout' ||
          key === 'Yahoo' ||
          key === 'Vimeo' ||
          key === 'Pinterest' ||
          key === 'LinkedIn'
        ) {
          const urlParts = stripTrailingSlash(social.url).split('/');
          subText = urlParts[urlParts.length - 1];
        }

        // Creates profile if we want multiple of each type of profile (ex: multiple Facebook profiles)
        if (displayMultipleOfEachSocialMediaType) {
          const socialProfileInfo = {
            subText,
            class: `wl-card-item--icon-${platformName}`,
            href: social.url,
            target: '_blank'
          };
          const socialProfileArray = [key, socialProfileInfo];
          multipleOfEachSocialProfilesArr.push(socialProfileArray);
          continue;
        }

        // Creates social media profiles for if we only want one of each type of profile (ex: only 1 facebook profile)
        // Check if social media profile is not defined
        if (typeof oneOfEachSocialProfilesObj[key] === 'undefined') {
          // Define a social media profile object since it was undefined above
          oneOfEachSocialProfilesObj[key] = {
            subText,
            subTextCompanion: null,
            class: `wl-card-item--icon-${platformName}`,
            href: social.url,
            target: '_blank',
            count: 1
          };

          continue;
        }
        // Update count
        oneOfEachSocialProfilesObj[key].count =
          oneOfEachSocialProfilesObj[key].count + 1;

        // Update sub text companion
        oneOfEachSocialProfilesObj[
          key
        ].subTextCompanion = `+${oneOfEachSocialProfilesObj[key].count} others`;
      }
    }
    let finalSocialMediaProfiles = [];
    if (
      Object.entries(oneOfEachSocialProfilesObj).length === 0 &&
      multipleOfEachSocialProfilesArr.length === 0
    ) {
      // set final to default social media, since there are none
      finalSocialMediaProfiles.push([
        'Facebook',
        {
          class: 'wl-card-item--icon-facebook',
          href: `https://www.facebook.com/search/people/?q=${firstName}%20${lastName}`,
          subText: null,
          subTextCompanion: null,
          target: '_blank'
        }
      ]);
      finalSocialMediaProfiles.push([
        'LinkedIn',
        {
          class: 'wl-card-item--icon-linkedin',
          href: `https://www.linkedin.com/search/results/people/?firstName=${firstName}&lastName=${lastName}&origin=SEO_PSERP&sid=lf*`,
          subText: null,
          subTextCompanion: null,
          target: '_blank'
        }
      ]);
    } else if (
      displayMax &&
      Object.entries(oneOfEachSocialProfilesObj).length !== 0
    ) {
      // Set final with random social media profiles of one type by the display max
      finalSocialMediaProfiles = Object.entries(oneOfEachSocialProfilesObj)
        .sort(() => 0.5 - Math.random())
        .slice(0, displayMax);
    } else if (displayMax && multipleOfEachSocialProfilesArr.length !== 0) {
      // Set final with random social media profiles with multiple of one type by the display max
      finalSocialMediaProfiles = multipleOfEachSocialProfilesArr
        .sort(() => 0.5 - Math.random())
        .slice(0, displayMax);
    } else {
      finalSocialMediaProfiles = Object.entries(oneOfEachSocialProfilesObj);
    }

    return {
      title: socialMediaTitle,
      ctaTitle: socialMediaCtaTitle,
      links: finalSocialMediaProfiles
    };
  },
  /**
   * Return an array of formatted random phone numbers from list of people
   * @param people
   * @param amount
   * @returns {[]}
   */
  setPhoneNumbers(people, amount = 6) {
    let phones = [];
    const formattedPhones = [];

    // Add all phone numbers
    people.forEach(person => {
      if (!(person.phones && person.phones.length > 0)) {
        return;
      }
      phones = phones.concat(person.phones.map(({ full }) => full));
    });

    // Select Random Phone Numbers
    const randomPhones = phones
      .sort(() => 0.5 - Math.random())
      .slice(0, amount);

    // Format Random Phones
    randomPhones.forEach(number => {
      const formattedNumber = this.maskPhoneNumber(number, '', true, 6);
      formattedPhones.push(formattedNumber);
    });

    return formattedPhones;
  },
  setProfilePhones(person, noMasking = false) {
    let phones = [];
    const formattedPhones = [];

    if (!(person.phones && person.phones.length > 0)) {
      return phones;
    }

    // Add all phone numbers
    phones = phones.concat(person.phones.map(({ full }) => full));

    // Format Random Phones
    phones.forEach((number, index) => {
      let shouldMask = true;
      if (index < 3 || noMasking) {
        shouldMask = false;
      }
      const formattedNumber = this.maskPhoneNumber(number, '', shouldMask, 6);
      formattedPhones.push(formattedNumber);
    });

    return formattedPhones;
  },
  /**
   * Return an array of formatted random email addresses from list of people
   * @param people
   * @param amount
   * @returns {[]}
   */
  setEmailAddresses(people, amount = 6) {
    let emails = [];
    const formattedEmails = [];

    // Add all email addresses
    people.forEach(person => {
      if (!(person.emails && person.emails.length > 0)) {
        return;
      }
      emails = emails.concat(person.emails.map(({ full }) => full));
    });

    // Select Random Emails
    const randomEmails = emails
      .sort(() => 0.5 - Math.random())
      .slice(0, amount);

    // Format Emails
    randomEmails.forEach(email => {
      if (!email) {
        return;
      }
      const formattedEmail = this.maskEmail(email, '', true);
      formattedEmails.push(formattedEmail);
    });

    return formattedEmails;
  },
  /**
   * Return randomized and limited list of formatted employment history
   *
   * @param {array} people
   * @param {number|null} amount
   * @returns {*[]}
   */
  setEmploymentHistory(people, amount = 6) {
    const employers = [];

    for (let i = 0; i < people.length; i++) {
      if (people[i].employers) {
        for (let e = 0; e < people[i].employers.length; e++) {
          employers.push(people[i].employers[e]);
        }
      }
    }

    // Get random employers based on amount
    const randomEmployers = this.getRandomItems(employers, amount);

    // Return formatted employers
    return randomEmployers.map(e => {
      e.businessname = this.formatName(e.businessname);
      e.position = this.formatName(e.position);
      return e;
    });
  },
  setProfileEmails(person) {
    let emails = [];
    const formattedEmails = [];

    if (!(person.emails && person.emails.length > 0)) {
      return emails;
    }
    // Add all email addresses
    emails = emails.concat(person.emails.map(({ full }) => full));

    // Format Emails
    emails.forEach(email => {
      if (!email) {
        return;
      }
      const formattedEmail = this.maskEmail(email.toLowerCase(), '', true);
      formattedEmails.push(formattedEmail);
    });

    return formattedEmails;
  },
  /**
   * Return an array of formatted random addresses from list of people
   * @param {object} people
   * @param {number} amount
   * @param shouldMaskStreet
   * @returns {[]}
   */
  setAddresses(people, amount = 6, shouldMaskStreet = true) {
    let addressesValue = [];
    const formattedAddresses = [];

    // Add all addresses
    people.forEach(person => {
      const { addresses } = person;

      addressesValue = [...addressesValue, ...addresses];
    });

    // Select Random Addresses
    const randomAddresses = addressesValue
      .sort(() => 0.5 - Math.random())
      .slice(0, amount);

    // Format Addresses
    randomAddresses.forEach(address => {
      const formattedAddress = this.formatAddress(
        address,
        '',
        shouldMaskStreet
      );

      formattedAddresses.push(formattedAddress);
    });

    return formattedAddresses;
  },
  /**
   * Formats a string of current street address with street number, name, type, and affix
   * @param person
   * @returns {string|*|string}
   */
  setCurrentStreet(person) {
    let baseAddress = person;
    let streetAddress = '';

    if (!baseAddress.current_address) {
      baseAddress = person.addresses[0];
    }

    if (!baseAddress.street_number) {
      return streetAddress;
    }

    streetAddress += baseAddress.street_number;

    if (baseAddress.street_name) {
      streetAddress += ' ' + baseAddress.street_name;
    }

    if (baseAddress.street_type) {
      streetAddress += ' ' + baseAddress.street_type;
    }

    if (baseAddress.street_affix) {
      streetAddress += ' ' + baseAddress.street_affix;
    }

    streetAddress = this.formatName(streetAddress + ',');

    return streetAddress;
  },
  /**
   * Formats a string of current location with city, state and zip
   * @param person
   * @returns {string}
   */
  setCurrentLocation(person) {
    let baseAddress = person;
    let locationAddress = '';

    if (!baseAddress.current_address) {
      baseAddress = person.addresses[0];
    }

    if (baseAddress.city) {
      locationAddress += this.formatName(baseAddress.city + ', ');
    }

    if (baseAddress.state) {
      locationAddress += baseAddress.state + ' ';
    }

    if (baseAddress.zip) {
      locationAddress += baseAddress.zip;
    }

    return locationAddress;
  },
  /**
   * Gets person's current address object.
   * @param person
   * @returns {obj}
   */
  getCurrentAddressObj(person) {
    const currentAddress = person.addresses.filter(person => {
      return person.current_address;
    });
    if (!currentAddress.length) {
      return person.addresses[0];
    }
    return currentAddress[0];
  },
  /**
   *
   * @param {object} person
   * @param {boolean} useCurrentAddress
   * @returns {*[]}
   */
  setProfileAddresses(
    person,
    useCurrentAddress = false,
    shouldMask = true,
    startAt = 2
  ) {
    // Add current address
    let addresses = [];
    const formattedAddresses = [];

    // Add all previous addresses
    if (Array.isArray(person.addresses)) {
      person.addresses.forEach(location => {
        addresses = [...addresses, location];
      });
    }

    const formatAddresses = useCurrentAddress
      ? [this.getCurrentAddressObj(person)]
      : this.getPastAddresses(addresses);
    // Format Addresses
    formatAddresses.forEach((address, index) => {
      let shouldMaskStreet;
      if (shouldMask) {
        shouldMaskStreet = true;
        if (index < startAt) {
          shouldMaskStreet = false;
        }
      } else {
        shouldMaskStreet = false;
      }
      const formattedAddress = this.formatAddress(
        address,
        '',
        shouldMaskStreet
      );

      formattedAddresses.push(formattedAddress);
    });

    return formattedAddresses;
  },
  /**
   * Gets past addresses, if no current address exists, the first index of addresses will be removed and considered the current
   * @param {addresses} Array
   * @returns {Array}
   */
  getPastAddresses(addresses) {
    const pastAddresses = addresses.filter(address => !address.current_address);
    const currentAddressExists = addresses.length !== pastAddresses.length;
    return currentAddressExists ? pastAddresses : addresses.slice(1);
  },
  setLandingUrl(params, firstName, lastName, state, city) {
    let url = NBars.transform(landingUrl, {
      firstName,
      lastName
    });

    if (state) {
      url += `&state=${state}`;
    }

    if (city) {
      url += `&city=${city}`;
    }

    if (Object.keys(params).length !== 0) {
      Object.keys(params).forEach(param => {
        url += `&${param}=${params[param]}`;
      });
    }

    return url;
  },
  getProfileLink(person, router) {
    const { href } = router.resolve({
      name: 'seo.profile',
      params: {
        firstName: person.firstname,
        lastName: person.lastname,
        city: person.city,
        stateAbbr: person.state,
        uuid: person.id
      }
    });
    return href;
  },
  setQnaTitle(firstName, lastName) {
    return NBars.transform(qnaTitle, {
      fullName: `${firstName} ${lastName}`
    });
  },
  setQnaSubtext(firstName, lastName) {
    return NBars.transform(qnaSubtext, {
      fullName: `${firstName} ${lastName}`
    });
  },
  setQnaTokens(people, firstName, lastName, phone) {
    return this.getQnaTokens(people, firstName, lastName, phone);
  },
  /**
   * Prevent duplicate relative links
   * @param people
   * @param firstName
   * @param lastName
   * @returns {*}
   */
  unlinkRelatives(people, firstName = null, lastName = null) {
    const uniqueNames = [];

    // Add name if available (Used primarily for root, state, city seo pages)
    if (firstName && lastName) {
      uniqueNames.push({
        firstname: firstName,
        lastname: lastName
      });
    }

    const newPeople = people;
    newPeople.forEach(function(person) {
      if (person.relatives) {
        person.relatives.forEach(function(relative) {
          const nameMatch = uniqueNames.filter(function(person) {
            return (
              person.firstname === relative.firstname &&
              person.lastname === relative.lastname
            );
          });

          if (nameMatch.length > 0) {
            relative['unlink'] = true;
            return relative;
          }

          uniqueNames.push({
            firstname: relative.firstname,
            lastname: relative.lastname
          });
        });
      }
    });

    return newPeople;
  },
  /**
   * Prevent duplicate phone links
   * @param people
   * @param phone
   * @returns {*}
   */
  unlinkPhones(people, phone = null) {
    const uniquePhones = [];

    // Add phone if available (Used primarily for phone seo page)
    if (phone) {
      uniquePhones.push(this.formatPhoneNumber(phone));
    }

    const newPeople = people;
    newPeople.forEach(person => {
      if (Array.isArray(person.phones) && person.phones.length) {
        person.phones.forEach(phoneItem => {
          const match = uniquePhones.filter(
            value => value === this.formatPhoneNumber(phoneItem.full)
          );

          if (match.length > 0) {
            phoneItem['unlink'] = true;
            return phoneItem;
          }

          uniquePhones.push(this.formatPhoneNumber(phoneItem.full));
        });
      }
    });

    return newPeople;
  },
  navigateToProfile(person, linkForNewTab = null) {
    const link = linkForNewTab
      ? linkForNewTab
      : this.formatEverFlowLink(person);
    window.open(link, '_blank');
    // use javascript to fill in the search form and trigger it
    const searchButton = document.querySelector('.tz-search__btn-search');
    const userFirstName = this.formatName(person.firstname);
    const userLastName = this.formatName(person.lastname);
    const userCity = this.formatName(person.city);
    const userState = person.state;
    const firstNameField = document.getElementsByName('firstName')[0];
    const lastNameField = document.getElementsByName('lastName')[0];
    const cityField = document.getElementsByName('city')[0];
    const stateField = document.getElementsByName('state')[0];
    firstNameField.value = userFirstName;
    lastNameField.value = userLastName;
    cityField.value = userCity;
    stateField.value = userState;
    // dispatch event to make sure it updates the v-model on tz-search
    firstNameField.dispatchEvent(new Event('input'));
    lastNameField.dispatchEvent(new Event('input'));
    cityField.dispatchEvent(new Event('input'));
    stateField.dispatchEvent(new Event('change'));
    searchButton.click();
  },
  parseCity(city) {
    if (!city) return false;

    const citySplitPlus = city.split('+');
    const citySplitDash = city.split('-');

    if (citySplitPlus.length > 1) {
      return citySplitPlus.join('%20');
    } else if (citySplitDash.length > 1) {
      return citySplitDash.join('%20');
    }

    return city;
  },
  setOffenseDate(date) {
    const defaultText = 'Unknown';
    if (!date) return defaultText;

    const formattedDate = new Date(date);
    const cutoffDate = new Date('1900-01-01');

    if (formattedDate < cutoffDate) {
      // Older than 1900s we cant trust the accuracy of date
      return defaultText;
    }

    return formattedDate.getFullYear();
  },
  setCrimeLocation(crime) {
    if (!crime.state && !crime.county) return 'Unknown';

    let location = '';

    if (crime.county !== '') {
      location += crime.county + ' County, ';
    }

    if (crime.state !== '') {
      location += crime.state;
    }

    return location;
  },
  maskCaseNumber(casenumber) {
    if (!casenumber) return 'Unknown';

    const masking = this.getMaskingString(casenumber.length - 3);
    const begin = casenumber.substring(0, 3);
    const maskedCase = `${begin}${masking}`;

    return maskedCase;
  }
};

export default resultHelper;
