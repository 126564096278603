<template>
  <tz-header
    id="app"
    :logo="isMenuOpened ? this.header.logo_full_color : logo"
    :logo-standard-size="logoSize"
    :logo-name="header.name"
    :logo-title="header.title"
    :logo-path="header.menu[0].url"
    :is-fixed="isFixed || isMenuOpened"
  >
    <template #header-bar>
      <button
        v-for="item in renderIf(header.menu.length > 0 && isMobile)"
        :key="item"
        class="tz-header__btn-menu"
        :class="{
          'tz-header__btn-menu--close': showMenu
        }"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="menuToggle"
      >
        <img
          :src="menuBtnImg"
          alt="Toggle Menu"
          loading="lazy"
          height="14"
          width="20"
          class="tz-header__btn-menu-img"
        />
      </button>
      <cs-menu
        :menu="header.menu"
        :is-mobile="isMobile"
        :active-link="selectedSection"
        :show-menu="!isMobile || showMenu"
        @handle-link-click="handleLinkClick"
      />
    </template>
    <nuxt ref="page" />
    <tz-footer
      :header-fixed="true"
      :footer="footer"
      :display-sections="['globalLinks']"
      :add-scripts="false"
      alpha-base-link="/"
      :page="page"
    />
  </tz-header>
</template>

<script>
import TzHeader from '@trazi/tz-header/src/tz-header.vue';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
import CsMenu from '@/components/cs-menu.vue';
import helpers from '@/assets/js/shared/misc/helpers';
const { renderIf } = helpers;

const closeMenuIcon = require('@/assets/images/shared/close-icon.svg');
const menuIcon = require('@/assets/images/shared/icon-menu.svg');

/**
 * Return mobile media query event for standard mobile max (768px)
 *
 * @returns {boolean|MediaQueryList}
 */
function getMobileQuery(width = 768) {
  // Ensure we do not error if method called before window object exists
  if (typeof window !== 'undefined') {
    return window.matchMedia(`(max-width: ${width}px)`);
  }

  return false;
}
const mobileMediaQuery = getMobileQuery(992);

export default {
  components: {
    TzHeader,
    CsMenu,
    TzFooter
  },
  props: {
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    },
    footer: {
      type: Object,
      default: () => require(`@/assets/cms/components/footer.json`)
    }
  },
  data() {
    return {
      logoSize: { height: '45', width: '236' },
      logoSmallSize: { height: '25', width: '26' },
      isFixed: false,
      logo: this.header.logo_white,
      scrollDesktopOffset: 79,
      selectedSection: 'home',
      selectedAnchor: null,
      isAutoScrolling: false,
      showMenu: false,
      isMobile: false,
      isScrollingFromClick: false,
      currentYPosition: 0,
      page: {
        path: this.$route.path,
        name: this.$route.name
      },
      renderIf
    };
  },
  computed: {
    menuBtnImg() {
      return this.showMenu ? closeMenuIcon : menuIcon;
    },
    isMenuOpened() {
      return this.isMobile && this.showMenu;
    }
  },
  methods: {
    addMediaQueryListener() {
      this.mediaQueryListener(mobileMediaQuery, this.setIsMobile);
      this.isMobile = mobileMediaQuery.matches;
    },
    setIsMobile(e) {
      this.showMenu = false;
      this.isMobile = e.matches;
    },
    /**
     * @param selector
     * @param handleMethod
     */
    mediaQueryListener(selector, handleMethod) {
      try {
        // Chrome & Firefox
        selector.addEventListener('change', handleMethod);
      } catch (err) {
        // Safari
        selector.addListener(handleMethod);
      }
    },
    /**
     * handles if header is fixed and what color the logo will be on scroll
     */
    handleScroll() {
      this.currentYPosition = window.pageYOffset;
      this.isFixed = !!window.scrollY;
      this.logo = this.isFixed
        ? this.header.logo_full_color
        : this.header.logo_white;
    },
    /**
     * Adds event listener click to the header logo
     */
    addLogoClick() {
      // cannot directly select tz-header__logo due to event listeners getting overwritten on updated
      const logoLink = document.getElementsByClassName('tz-header')[0];
      logoLink.addEventListener('click', event => {
        event.preventDefault();
        const logoAnchor = event.target.closest('a');
        if (logoAnchor?.className === 'tz-header__logo') {
          this.selectedSection = logoAnchor.hash.replace('#', '');
          if (this.showMenu) {
            this.menuToggle();
          }
          this.scrollToElement(this.selectedSection);
          window.location.hash = logoAnchor.hash;
        }
      });
    },
    /**
     * Adds click event listeners to footer links
     */
    addFooterClicks() {
      // cannot directly select tz-footer__link due to event listeners getting overwritten on updated
      const footer = document.getElementsByClassName('tz-footer')[0];
      footer.addEventListener('click', event => {
        event.preventDefault();
        const footerLink = event.target.closest('a');
        if (footerLink?.className === 'tz-footer__link') {
          this.selectedSection = footerLink.hash.replace('#', '');
          this.isScrollingFromClick = true;
          this.scrollToElement(this.selectedSection);
          window.location.href = footerLink.hash;
        }
      });
    },
    /**
     * Custom scrollTo with a smooth behavior that is supported on chrome, firefox and safari
     *
     * @param {string} element
     * @param {number} offset
     */
    scrollToElement(element) {
      this.selectedSection = element;
      const elementId = `${element}-anchor`;
      const offset = this.scrollDesktopOffset;
      const startingY = window.pageYOffset;
      const elementY =
        startingY +
        document.getElementById(elementId).getBoundingClientRect().top;
      // If element is close to page's bottom then window will scroll only to some position above the element.
      const isElementNearBottom =
        document.body.scrollHeight - elementY < window.innerHeight;
      const endOfPageY = document.body.scrollHeight - window.innerHeight;
      const targetY = isElementNearBottom ? endOfPageY : elementY;

      const diff = targetY - offset - startingY;

      const easing = function(t) {
        return t * t;
      };
      let start;
      this.isAutoScrolling = true;
      const vm = this;

      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        let percent = Math.min(time / 1000, 1);
        // Apply the easing
        percent = easing(percent);

        window.scrollTo(0, startingY + diff * percent);

        if (time < 1000) {
          window.requestAnimationFrame(step);
        } else {
          vm.isAutoScrolling = false;
        }
      });
    },
    handleLinkClick(linkClicked) {
      this.selectedSection = linkClicked;
      if (this.showMenu) {
        this.menuToggle();
      }
      this.isScrollingFromClick = true;
      this.scrollToElement(linkClicked);
      window.location.href = `#${linkClicked}`;
    },
    menuToggle() {
      if (this.isMobile) {
        this.showMenu = !this.showMenu;
      }
    },
    handleHashChange(evt) {
      evt.preventDefault();
      if (!this.isScrollingFromClick) {
        this.scrollToHash(this.currentYPosition);
      } else {
        this.isScrollingFromClick = false;
      }
    },
    scrollToHash(startingY) {
      const urlHash = window.location.href.split('#')[1];
      if (urlHash) {
        window.scrollTo(0, startingY);
        this.scrollToElement(urlHash);
      }
    }
  },
  created() {
    try {
      this.$nuxt.$on('banner-link-click', $event =>
        this.handleLinkClick($event)
      );
    } catch (e) {
      // do nothing
    }
  },
  mounted() {
    this.scrollToHash(0);
    window.addEventListener('hashchange', this.handleHashChange);

    this.addLogoClick();
    this.addFooterClicks();

    window.addEventListener('scroll', this.handleScroll, { passive: true });

    const sections = document.querySelectorAll('.home__page-anchor');
    const navLinks = document.getElementsByClassName('cs-menu__nav-link');

    // for absolute positioned element
    const aboutAnchor = document.getElementById('about-anchor');
    const aboutAnchorTop = aboutAnchor.getBoundingClientRect().top;

    window.addEventListener('scroll', () => {
      let currentSection = '';
      sections.forEach(section => {
        const sectionId = section.getAttribute('id');
        let sectionTop = section.offsetTop;
        let sectionHeight = section.clientHeight;

        if (pageYOffset >= sectionTop - sectionHeight / 3) {
          currentSection = sectionId.split('-')[0];
        }
        if (pageYOffset === 0) {
          currentSection = 'home';
        }
      });

      for (let i = 0; i < navLinks.length; i++) {
        const link = navLinks[i];
        if (link.hash === `#${currentSection}` && !this.isAutoScrolling) {
          this.selectedSection = currentSection;
        }
      }
    });

    this.addMediaQueryListener();
    this.showMenu = this.isMobile ? false : true;
  }
};
</script>
