<template>
  <div class="cs-menu" :class="{ 'cs-menu--hide': !showMenu }">
    <nav
      v-for="item in renderIf(hasMenu)"
      :key="item"
      class="cs-menu-navbar"
      :class="{
        'cs-menu__navbar--mobile': isMobile
      }"
    >
      <ul class="cs-menu__nav">
        <li class="cs-menu__nav-item" v-for="link in menu" :key="link.label">
          <a
            class="cs-menu__nav-link"
            :class="{
              'cs-menu__nav-link--active': isLinkActive(link.url)
            }"
            :href="link.url"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            v-text="link.label"
            @click="handleLinkClick"
          />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import helpers from '@/assets/js/shared/misc/helpers';
const { renderIf } = helpers;

export default {
  name: 'TzHeader',
  props: {
    menu: {
      type: Array,
      default: Array
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    activeLink: {
      type: String,
      default: String
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasMenu() {
      return this.menu && this.menu.length > 0;
    }
  },
  data() {
    return {
      show: false,
      renderIf
    };
  },
  methods: {
    menuToggle() {
      this.show = !this.show;
    },
    isLinkActive(linkUrl) {
      return this.activeLink === linkUrl.replace('#', '');
    },
    handleLinkClick(event) {
      event.preventDefault();
      const linkClicked = event.target.hash.replace('#', '');
      this.$emit('handle-link-click', linkClicked);
    }
  }
};
</script>
