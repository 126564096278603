const lettersOnly = new RegExp('^[A-Za-z]+$');
const lettersAndDashes = new RegExp('^[A-Za-z\\-]+$');
const lettersDashesAndSpaces = new RegExp('^([a-zA-Z\\s\\-]+)$');
const lettersDashesAndUnderscores = new RegExp('^[A-Za-z_\\-]+$');
const emailRegex = /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const spaceRegEx = new RegExp(/ |\+/, 'g');
const dashRegEx = new RegExp('-', 'g');
const underscoreRegEx = new RegExp('_', 'g');
const isDashboardRoute = /dashboard/;
const isDashboardSubRoutes = /dashboard-reports-person|dashboard-search/;

const states = {
  AE: 'Armed Forces',
  AP: 'Armed Forces Pacific',
  AA: 'Armed Forces America',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AL: 'Alabama',
  AK: 'Alaska',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  FM: 'Federated State of Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};
const territoryStates = [
  'AA',
  'AE',
  'AP',
  'AS',
  'DC',
  'FM',
  'GU',
  'MH',
  'MP',
  'PR',
  'PW',
  'VI'
];
const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

const ages = [
  {
    value: '',
    name: 'All Ages'
  },
  {
    value: '18-30',
    name: '18-30'
  },
  {
    value: '31-40',
    name: '31-40'
  },
  {
    value: '41-50',
    name: '41-50'
  },
  {
    value: '51-60',
    name: '51-60'
  },
  {
    value: '61-110',
    name: '61-110'
  }
];

const helpers = {
  lettersAndDashes,
  emailRegex,
  lettersDashesAndSpaces,
  passwordRegex,
  spaceRegEx,
  dashRegEx,
  underscoreRegEx,
  isDashboardRoute,
  isDashboardSubRoutes,
  /**
   * Returns an array of the alphabet
   *
   * @returns {string[]}
   */
  getAlphabetList() {
    return alphabet;
  },
  /**
   * Returns an array of objects with the name and value options for the age dropdown
   *
   * @returns {[{name: string, value: string}]}
   */
  getAgesForDropdown() {
    return ages;
  },
  /**
   * If statement vue hack to prevent <!-- --> comment
   *
   * @param condition
   * @returns {[]}
   */
  renderIf(condition) {
    return condition ? [Math.random()] : [];
  },
  /**
   * If statement vue hack to prevent <!-- --> comment
   * Returns empty array or content as array
   *
   * @param condition
   * @returns {[]}
   */
  renderIfReturnArray(condition) {
    if (!condition) {
      return [];
    }

    return Array.isArray(condition) ? condition : [condition];
  },
  /**
   * Returns an array of objects with the name and abbreviation of each state
   * @param {array|null} selectStates
   * @param {boolean} useStateAbbrAsValue
   * @returns [{}]
   */
  getStatesForDropdown(selectStates = null, useStateAbbrAsValue = true) {
    const statesArray = [];
    if (selectStates) {
      selectStates.forEach(state => {
        if (state.displayName && state.displayName !== 'All States') {
          statesArray.push({
            value: this.getStateAbbrFromName(state.displayName),
            name: state.displayName
          });
        }
      });
    } else {
      for (const property in states) {
        statesArray.push({
          value: useStateAbbrAsValue
            ? property
            : this.getStateFromAbbr(property)
                .replace(/ /g, '-')
                .toLowerCase(),
          name: states[property]
        });
      }
    }
    statesArray.unshift({ value: '', name: 'All States' });
    return statesArray;
  },
  /**
   * Convert an array of objects to array with only submitted key values
   * ex. key=id, [{label: x, id: y}, {label: z, id: a}] => [y, z]
   *
   * @param {array} array
   * @param {string} key
   * @returns {[]}
   */
  convertArrayOfObjectKeysToArrayOfValues: function(array, key) {
    const newArray = [];

    for (let i = 0; i < array.length; i++) {
      newArray.push(array[i][key]);
    }

    return newArray;
  },
  /**
   * Convert an array of objects to new object consisting of specified key/value pairings
   * ex. key=label value=id, [{label: x, id: y}, {label: z, id: a}] => {x:y, z:a}
   *
   * @param {array} array
   * @param {string} key
   * @param {string} value
   * @returns {{}}
   */
  convertArrayOfObjectsToObject: function(array, key, value) {
    const newObject = {};

    for (let i = 0; i < array.length; i++) {
      newObject[array[i][key]] = array[i][value];
    }

    return newObject;
  },
  // Return the browser name from passed in user agent
  detectBrowser: function(userAgent) {
    if (userAgent.indexOf('Edg') > -1) {
      return 'Edge';
    }

    if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    }

    if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    }

    if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    }

    if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('rv:') > -1) {
      return 'IE';
    }

    return null;
  },
  /**
   * Return whether browser is on iphone or not
   * @param  userAgent
   * @returns {boolean}
   */
  detectIPhone: function(userAgent) {
    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('iPhone') > -1) {
      return true;
    }
    return false;
  },
  /**
   * Return User Agent from Request or Window Navigator if Request does not exist
   * @param {object|null} req
   * @returns {*}
   */
  getUserAgentFromRequestOrNavigator(req) {
    return req ? req.headers['user-agent'] : navigator.userAgent;
  },
  /**
   * Return URL with base path and params
   *
   * @param {string} baseUrl - expects absolute path
   * @param {object} params ex. {aid: 17, tid: 17, firstName: William, lastName: Smith}
   * @returns {string}
   */
  getLinkWithParams: function(baseUrl, params) {
    const url = new URL(baseUrl);

    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    return url.href;
  },
  /**
   * Return url params from sessionData, skipping duplicates and formatting providerId as providerID
   *
   * @param {url} baseUrl
   * @param {object} sessionData
   * @returns {*}
   */
  getParamsForUrlFormattedProviderID: function(baseUrl, sessionData) {
    const url = baseUrl;
    /** Go through landing data **/
    Object.entries(sessionData).forEach(value => {
      /** Response data can repeat non-response data. Add response data, then any other data if it is not yet set **/
      if (value[0] === 'response') {
        Object.entries(value[1]).forEach(val => {
          /** Provider Id should always be providerID in url **/
          const key = val[0] === 'providerId' ? 'providerID' : val[0];
          url.searchParams.set(key, val[1]);
        });
      } else if (!url.searchParams.get(value[0])) {
        /** Provider Id should always be providerID in url **/
        const key = value[0] === 'providerId' ? 'providerID' : value[0];
        url.searchParams.set(key, value[1]);
      }
    });

    return url;
  },
  getStateList: function() {
    return states;
  },
  /**
   * Return full state name from standard abbreviation
   *
   * @param {string} stateAbbr
   */
  getStateFromAbbr: function(stateAbbr) {
    return states[stateAbbr];
  },
  /**
   * Return standard abbreviation from full state name
   *
   * @param {string} state
   */
  getStateAbbrFromName: function(state) {
    if (!state) {
      return;
    }

    if (state.length === 2) {
      return state.toUpperCase();
    }

    for (let key in states) {
      if (state.toLowerCase() === states[key].toLowerCase()) {
        return key;
      }
    }
  },
  /**
   * @param {string} stateAbbr
   * @returns {boolean}
   */
  isStateATerritory: function(stateAbbr) {
    return territoryStates.indexOf(stateAbbr) > -1;
  },
  /**
   * @param {string} test
   * @param {number} number
   * @returns {boolean}
   */
  hasCharCount: function(test, number) {
    return test.length === number;
  },
  isValidStateAbbr: function(test) {
    return !!this.getStateFromAbbr(test);
  },
  /**
   * @param {string} test
   * @returns {boolean}
   */
  isOnlyLetters: function(test) {
    return lettersOnly.test(test);
  },
  /**
   * @param {string} test
   * @returns {boolean}
   */
  isOnlyLettersAndDashes: function(test) {
    return lettersAndDashes.test(test);
  },
  /**
   * Returns true if string contains only letters, dashes, and/or spaces
   *
   * @param {string} test
   * @returns {boolean}
   */
  isOnlyLettersDashesSpaces: function(test) {
    return lettersDashesAndSpaces.test(test);
  },
  /**
   * @param {string} test
   * @returns {boolean}
   */
  isOnlyLettersDashesUnderscores: function(test) {
    return lettersDashesAndUnderscores.test(test);
  },
  isOnlyLettersAndUrlEncoding: function(test) {
    const nameFilter = RegExp("^[a-zA-Z _'-+]+$", 'g');
    test.replace('_', '%20');
    return nameFilter.test(test);
  },
  /**
   * Navigate helper that allows us to redirect to any page
   * as an alternative to router.push, when necessary
   *
   * @param {string} navigateUrl
   * @param {object} query
   * @returns {string}
   */
  navigateHelper: function(navigateUrl, query) {
    const queryParams = Object.keys(query);
    const queryString = queryParams
      .map(key => key + '=' + query[key])
      .join('&');

    const redirectUrl = location.origin + navigateUrl + '?' + queryString;

    return (location.href = redirectUrl);
  },
  /**
   * @param {string} text
   * @returns {string}
   */
  setTitleCase: text => {
    if (!text || text === '') {
      return;
    }

    return text.toLowerCase().replace(/(?:^|\s|\/|\-)\w/g, function(match) {
      return match.toUpperCase();
    });
  },
  /**
   * Chunks arrays (used for pagination)
   * @param arr
   * @param size
   * @returns {*[]}
   */
  chunk: (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    ),
  isMobile: (userAgent = navigator.userAgent) => {
    return /Android|webOS|iPhone|iPod|IEMobile|Opera Mini/i.test(userAgent);
  },
  /**
   * Return string with symbol in title case.
   * Default: ex jOhn SMITH => John Smith
   *
   * @param {string} name
   * @param {string|null} spacer
   * @param {string|null} separator
   * @returns {string|null}
   */
  formatName: function(name, spacer = null, separator = null) {
    if (!name) {
      return null;
    }

    spacer = spacer || ' ';
    separator = separator || ' ';

    if (!spacer) {
      spacer = ' ';
    }

    return name
      .split(separator)
      .map(function(item) {
        return helpers.setTitleCase(item);
      })
      .join(spacer);
  },
  formatFirstName(str, titleCase) {
    if (!str || str === '') {
      return;
    }

    const regex = /\B[A-Z]\B/g;
    let string = this.formatUnderScoreToSpace(str);

    if (titleCase) {
      string = this.formatName(str);
    }
    return string.replace(regex, '-$&');
  },
  formatCity(city) {
    if (!city) {
      return;
    }
    const noscoreCity = this.formatUnderScoreToSpace(city);
    const nodashCity = noscoreCity.replace(/-/g, ' ');
    return this.formatName(nodashCity);
  },
  formatUnderScoreToSpace(str) {
    if (!str || str === '') {
      return;
    }
    return str.replace(/_/g, ' ');
  },
  formatFullName(firstName, lastName) {
    return `${this.formatFirstName(firstName)} ${this.formatUnderScoreToSpace(
      lastName
    )}`;
  },
  getOptOutSession: function() {
    if (!sessionStorage.optout) {
      return {};
    }

    return JSON.parse(sessionStorage.optout);
  },
  setOptOutSession: function(optOut) {
    sessionStorage.setItem('optout', JSON.stringify(optOut));
  },
  /**
   * Removes any null, undefined, or empty string
   * values in obj
   *
   * @param {object} obh
   * @returns {object}
   */
  cleanData: function(obj) {
    for (let propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName];
      }
    }
    return obj;
  },
  setLandingSession: function(landing = {}) {
    landing = this.cleanData(landing);
    const landingSession = this.getLandingSession();

    // If provider ids are the same just merge the data
    if (
      typeof landingSession.providerID === 'string' &&
      typeof landing.providerID === 'string' &&
      landingSession.providerID.length > 1 &&
      landingSession.providerID === landing.providerID
    ) {
      landing = Object.assign({}, landingSession, landing);
    }

    sessionStorage.setItem('landing', JSON.stringify(landing));
  },
  getLandingSession: function() {
    if (typeof sessionStorage === 'undefined' || !sessionStorage.landing) {
      return {};
    }

    return JSON.parse(sessionStorage.landing);
  },
  objectToParams: function(object) {
    const query = Object.entries(object)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    return '?' + query;
  },
  urlWithParams: function(url, params) {
    return url + this.objectToParams(params);
  },
  /**
   * Formats a number into money
   * @param {number} number
   * @param {string} currency
   * @returns {string}
   */
  formatMoney(number, currency = 'USD') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(Number(number));
  },
  /**
   * Adds trailing slash at the end of text if not available
   * @returns {string}
   */
  getTrailingSlash(text) {
    return text.replace(/^\/?([^\/]+(?:\/[^\/]+)*)\/?$/, '/$1/') || '/';
  },
  /**
   * Removes duplicate addresses
   * @param addresses
   * @returns {*}
   */
  removeDuplicateAddresses(addresses) {
    return Array.isArray(addresses)
      ? addresses.filter(
          (address, index, self) =>
            self.findIndex(
              s =>
                s.street_name === address.street_name &&
                s.street_number === address.street_number
            ) === index
        )
      : [];
  },
  /**
   * @param selector
   * @param handleMethod
   */
  mediaQueryListener(selector, handleMethod) {
    try {
      // Chrome & Firefox
      selector.addEventListener('change', handleMethod);
    } catch (err) {
      // Safari
      selector.addListener(handleMethod);
    }
  },
  /**
   * Check if there's an error in the payload
   * @param {object} response
   * @param {string} errorType
   * @returns {boolean}
   */
  responseError(response, errorType) {
    return response && response.type === errorType;
  },
  /**
   * Outputs the formatted Label based on the property key from the object
   * @param label
   * @returns {string}
   */
  formatLabel(label) {
    const formattedLabel = label
      .match(/([A-Z]?[^A-Z]*)/g)
      .slice(0, -1)
      .join(' ');
    const uppercaseLabel =
      formattedLabel[0].toUpperCase() + formattedLabel.substring(1);
    return uppercaseLabel;
  },
  /**
   *
   * @param a
   * @param b
   * @param {string} order
   * @return {number}
   */
  sortByAge(a, b, order) {
    if (order === 'asc') {
      return a.age - b.age;
    }
    return b.age - a.age;
  },
  /**
   *
   * @param a
   * @param b
   * @param {string} order
   * @return {number}
   */
  sortByName(a, b, order) {
    const nameA = `${a.firstname} ${a.middlename && a.middlename} ${
      a.lastname
    }`;
    const nameB = `${b.firstname} ${b.middlename && b.middlename} ${
      b.lastname
    }`;
    if (order === 'asc') {
      return nameA > nameB ? 1 : -1;
    }
    return nameB > nameA ? 1 : -1;
  },
  /**
   *
   * @param {array} locationList
   * @returns {number|number}
   */
  otherLocationsCount(locationList) {
    return locationList.length > 3 ? locationList.length - 3 : 0;
  },
  /**
   *
   * @param {array} locationList
   * @returns {boolean}
   */
  hasOtherLocations(locationList) {
    return this.otherLocationsCount(locationList) > 0;
  },
  /**
   *
   * @param {array} locationList
   * @returns {string|*}
   */
  formattedLocations(locationList) {
    const locations = locationList.slice(0, 3);
    const locationsLength = locations.length;
    const isOnlyOneItem = locationsLength === 1;

    // If only one location, early exit and return the name
    if (isOnlyOneItem) {
      return locations[0].name;
    }

    let list = '';
    // Grab all locations from array
    for (let i = 0; i < locationsLength; i++) {
      const isLastItem = i === locationsLength - 1;
      // Space should be added for lists with only two items, or for first and second item only in least of three total
      const addSpace =
        locationsLength === 2 ||
        (!this.hasOtherLocations(locationList) && i === 1);
      // Only add starter of and for last item with no other locations
      const starter =
        isLastItem && !this.hasOtherLocations(locationList) ? 'and ' : '';
      // End with nothing for last item, space if qualifies, otherwise comma and space
      const delineator = isLastItem ? '' : addSpace ? ' ' : ', ';
      // Add to string of locations
      list += `${starter}${locations[i].name}${delineator}`;
    }

    return list;
  },
  /**
   * Returns a random number between min and max
   * @param min
   * @param max
   * @returns {number|*}
   */
  rand: function(min, max) {
    if (min == 0) {
      return Math.floor(Math.random() * max + 0);
    } else {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  /**
   * Get limited array of random items from large array
   * @param stuff
   * @param limit
   * @returns {*[]}
   */
  getRandomItems: function(stuff, limit) {
    // Randomly select limited crimes
    const items = [];
    for (let i = 0; i < stuff.length && i < limit; i++) {
      const item = stuff[Math.floor(Math.random() * stuff.length)];

      if (items.includes(item)) {
        // Count down if item already exists
        // and dont add to list
        i--;
      } else {
        // Add item to list
        items.push(item);
      }
    }
    return items;
  },
  /**
   * Formats phone number. e.g. (123) 345-6789
   *
   * @param {string} phoneNumberString
   * @returns {string}
   */
  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
  },
  /**
   * Formats phone number with dashes only. e.g. 1234567890 -> 123-456-7890
   *
   * @param phoneNumber
   * @returns {string}
   */
  dashedPhoneNumber(phoneNumber) {
    return phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  },
  /**
   * Calculates age from dob property returned in the response
   *
   * @param dateString
   * @returns {null|number}
   */
  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);

    if (1900 >= birthDate.getFullYear()) {
      return null;
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  },
  /**
   * Formats date of birth to make it easier to read. e.g. 1990-01-25 -> January 25th, 1990
   *
   * @param {string} dob
   * @param {boolean} yearOnly
   * @returns {string | null}
   */
  formatDateOfBirth(dob, yearOnly = false) {
    if (!dob) {
      return null;
    }
    const birthDate = new Date(dob);
    const month = birthDate.toLocaleString('default', {
      month: 'long'
    });
    const day = new Date(dob.replace(/-/g, '/')).getDate(); // replacing - with / on the date of birth fixes the date being of by 1
    const nth = function(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };
    const year = birthDate.getFullYear();

    if (1900 >= year) {
      return null;
    }

    if (yearOnly) {
      return year.toString();
    }

    return `${month} ${day}${nth(day)}, ${year}`;
  },
  /**
   * Converts a one dimensional array into a multidimensional array
   *
   * @param {[{firstname: string, rank: number, state: string, lastname: string}]} array
   * @param {number} size
   * @return {any[]}
   */
  splitArray(array, size = 4) {
    let columns = [];
    let mid = Math.ceil(array.length / size);
    for (let col = 0; col < size; col++) {
      columns.push(array.slice(col * mid, col * mid + mid));
    }
    return columns;
  },
  /**
   * Display person's name on the table
   * If they have a state, display it next to the name in parenthesis
   *
   * @param person
   * @returns {string}
   */
  displayNameOnTable(person) {
    const { firstname, lastname, state } = person;
    const formattedFirstName = this.formatName(firstname);
    const formattedLastName = this.formatName(lastname);
    let name = '';

    if (firstname) {
      name += formattedFirstName + ' ';
    }

    if (lastname) {
      name += formattedLastName;
    }

    if (state) {
      name += ` (${state})`;
    }

    return name;
  },
  /**
   * Links person to the root page
   *  If they have a state, link the state page
   *
   * @param person
   * @returns {string}
   */
  linkNameOnTable(person, location) {
    const { firstname, lastname, state } = person;
    const firstNameParam = this.parseAsParamFirstName(
      this.formatName(firstname)
    );
    const lastNameParam = this.parseAsParamLastName(this.formatName(lastname));
    const place = location ? location : state;

    if (!place) {
      return `/${firstNameParam}-${lastNameParam}/`;
    }

    return `/${firstNameParam}-${lastNameParam}/${place}/`;
  },
  /**
   * Custom scrollTo with a smooth behavior that is supported on chrome, firefox and safari
   *
   * @param {string} element
   * @param {number} offset
   */
  scrollToElement(element, offset) {
    const startingY = window.pageYOffset;
    const elementY =
      window.pageYOffset +
      document.getElementById(element).getBoundingClientRect().top;
    // If element is close to page's bottom then window will scroll only to some position above the element.
    const targetY =
      document.body.scrollHeight - elementY < window.innerHeight
        ? document.body.scrollHeight - window.innerHeight
        : elementY;
    const diff = targetY - offset - startingY;
    // Easing function: easeInQuad
    const easing = function(t) {
      return t * t;
    };
    let start;

    if (!diff) return;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      let percent = Math.min(time / 1000, 1);
      // Apply the easing
      percent = easing(percent);

      window.scrollTo(0, startingY + diff * percent);

      if (time < 1000) {
        window.requestAnimationFrame(step);
      }
    });
  },
  setSelectedPersonSession: function(selectedPerson) {
    sessionStorage.setItem('selectedPerson', JSON.stringify(selectedPerson));
  },
  getSelectedPersonSession: function() {
    if (!sessionStorage.selectedPerson) {
      return {};
    }

    return JSON.parse(sessionStorage.selectedPerson);
  }
};

export default helpers;
